/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import isEmpty from 'lodash-es/isEmpty';
import { convertWattToKilowatt, getFormattedPVValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { DEFAULT_LANGUAGE, ENERGY_PRODUCT_TYPE, NETWORK_TYPE, NETWORK_TYPE_IMAGE, } from '@hems/util/src/constant';
import { isEnergyPolicyType } from '@hems/util/src/helper/tsguardHelper';
export const defaultEnergyFlow = {
    isPvToBattery: false,
    isPvToGrid: false,
    isPvToLoad: false,
    isBatteryToGrid: false,
    isBatteryToLoad: false,
    isGridToBattery: false,
    isGridToLoad: false,
    isGeneratorToLoad: false,
};
const energyFlowCaseMap = {
    case0: { ...defaultEnergyFlow },
    case1_1: { ...defaultEnergyFlow, isPvToLoad: true, isBatteryToLoad: true, isGridToLoad: true },
    case1_2: { ...defaultEnergyFlow, isBatteryToLoad: true, isGridToLoad: true },
    case2_1: { ...defaultEnergyFlow, isPvToLoad: true, isPvToBattery: true, isPvToGrid: true },
    case2_2: { ...defaultEnergyFlow, isPvToBattery: true, isPvToGrid: true },
    case2_3: { ...defaultEnergyFlow, isPvToLoad: true, isPvToGrid: true },
    case2_4: { ...defaultEnergyFlow, isPvToGrid: true },
    case2_5: { ...defaultEnergyFlow, isPvToLoad: true, isPvToBattery: true },
    case2_6: { ...defaultEnergyFlow, isPvToBattery: true },
    case2_7: { ...defaultEnergyFlow, isPvToLoad: true },
    case3_1_1: { ...defaultEnergyFlow, isPvToLoad: true, isBatteryToLoad: true, isBatteryToGrid: true },
    case3_1_2: { ...defaultEnergyFlow, isPvToLoad: true, isBatteryToGrid: true },
    case3_1_3: { ...defaultEnergyFlow, isBatteryToLoad: true, isBatteryToGrid: true },
    case3_1_4: { ...defaultEnergyFlow, isBatteryToGrid: true },
    case3_1_5: { ...defaultEnergyFlow, isPvToLoad: true, isBatteryToLoad: true },
    case3_1_6: { ...defaultEnergyFlow, isBatteryToLoad: true },
    case3_2_1: { ...defaultEnergyFlow, isPvToLoad: true, isPvToGrid: true, isBatteryToGrid: true },
    case3_2_2: { ...defaultEnergyFlow, isPvToGrid: true, isBatteryToGrid: true },
    case4_1_1: { ...defaultEnergyFlow, isPvToBattery: true, isPvToLoad: true, isGridToLoad: true },
    case4_1_2: { ...defaultEnergyFlow, isPvToBattery: true, isGridToLoad: true },
    case4_1_3: { ...defaultEnergyFlow, isPvToLoad: true, isGridToLoad: true },
    case4_1_4: { ...defaultEnergyFlow, isGridToLoad: true },
    case4_2_1: { ...defaultEnergyFlow, isPvToBattery: true, isGridToBattery: true, isGridToLoad: true },
    case4_2_2: { ...defaultEnergyFlow, isPvToBattery: true, isGridToBattery: true },
    case4_2_3: { ...defaultEnergyFlow, isGridToBattery: true, isGridToLoad: true },
    case4_2_4: { ...defaultEnergyFlow, isGridToBattery: true },
    caseGeneratorToLoad: { ...defaultEnergyFlow, isGeneratorToLoad: true },
};
// eslint-disable-next-line complexity
export const getEnergyFlowCases = (data, isGenerator = false) => {
    const { pv_pw: pv, bt_pw: battery, grid_pw: grid, cons_pw: load } = data;
    /** generator producing */
    if (isGenerator) {
        return energyFlowCaseMap.caseGeneratorToLoad;
    }
    /** battery discharge, grid export, pv producing */
    if (battery > 0 && grid < 0 && pv > load) {
        if (load > 0) {
            /** battery - grid 대응, pv - load, grid 모두 대응 */
            return energyFlowCaseMap.case3_2_1;
        }
        /** battery - grid 대응, pv - grid 대응 */
        return energyFlowCaseMap.case3_2_2;
    }
    /** battery discharge, grid export, pv producing */
    if (battery === 0 && grid < 0 && pv > 0) {
        if (load > 0) {
            /** pv - load, grid 모두 대응 */
            return energyFlowCaseMap.case2_3;
        }
        /** pv - grid 대응 */
        return energyFlowCaseMap.case2_4;
    }
    /** battery charge, grid import, pv producing */
    if (battery < 0 && grid > 0 && grid > load && pv > 0) {
        if (load > 0) {
            /** pv - battery 대응, grid - battery, load 모두 대응 */
            return energyFlowCaseMap.case4_2_1;
        }
        /** pv - battery 대응, grid - battery 대응 */
        return energyFlowCaseMap.case4_2_2;
    }
    /** battery discharge, grid import */
    if (battery > 0 && grid > 0) {
        if (pv > 0) {
            /** pv - load 대응, battery - load 대응, grid - load 대응 */
            return energyFlowCaseMap.case1_1;
        }
        /** battery - load 대응, grid - load 대응 */
        return energyFlowCaseMap.case1_2;
    }
    /** battery charge, grid export, pv producing */
    if (battery < 0 && grid < 0 && pv > 0) {
        if (load > 0) {
            /** pv - load, battery, grid 모두 대응 */
            return energyFlowCaseMap.case2_1;
        }
        /** pv - battery, grid 모두 대응 */
        return energyFlowCaseMap.case2_2;
    }
    /** battery charge, pv producing */
    if (battery < 0 && grid === 0 && pv > 0) {
        if (load > 0) {
            /** pv - load, battery 모두 대응 */
            return energyFlowCaseMap.case2_5;
        }
        if (load === 0) {
            /** pv - battery 대응 */
            return energyFlowCaseMap.case2_6;
        }
        /** 대응 없음 */
        return energyFlowCaseMap.case0;
    }
    /** pv producing */
    if (battery === 0 && grid === 0 && pv > 0) {
        if (load > 0) {
            /** pv - load 대응 */
            return energyFlowCaseMap.case2_7;
        }
        /** 대응 없음 */
        return energyFlowCaseMap.case0;
    }
    /** battery discharge, grid export, pv producing */
    if (battery > 0 && grid < 0 && pv > 0) {
        if (pv < load) {
            /** pv - load 대응, battery - load, grid 모두 대응 */
            return energyFlowCaseMap.case3_1_1;
        }
        /** pv - load 대응, battery - grid 대응 */
        return energyFlowCaseMap.case3_1_2;
    }
    /** battery discharge, grid export */
    if (battery > 0 && grid < 0 && pv === 0) {
        if (load > 0) {
            /** battery - load, grid 모두 대응 */
            return energyFlowCaseMap.case3_1_3;
        }
        /** battery - grid 대응 */
        return energyFlowCaseMap.case3_1_4;
    }
    /** battery discharge */
    if (battery > 0 && grid === 0 && pv < load) {
        if (pv > 0) {
            /** pv - load 대응, battery - load 대응 */
            return energyFlowCaseMap.case3_1_5;
        }
        /** battery - load 대응 */
        return energyFlowCaseMap.case3_1_6;
    }
    /** battery charge, grid import, pv producing */
    if (battery < 0 && grid > 0 && pv > 0 && load > 0) {
        if (grid < load) {
            /** pv - battery, load 모두 대응, grid - load 대응 */
            return energyFlowCaseMap.case4_1_1;
        }
        /** pv - battery 대응, grid - load 대응 */
        return energyFlowCaseMap.case4_1_2;
    }
    /** grid import */
    if (battery === 0 && grid > 0 && grid <= load) {
        if (pv > 0) {
            /** pv - load 대응, grid - load 대응 */
            return energyFlowCaseMap.case4_1_3;
        }
        /** grid - load 대응 */
        return energyFlowCaseMap.case4_1_4;
    }
    /** battery charge, grid import */
    if (battery < 0 && grid > 0 && grid > load && pv === 0) {
        if (load > 0) {
            /** grid - battery, load 모두 대응 */
            return energyFlowCaseMap.case4_2_3;
        }
        /** grid - battery 대응 */
        return energyFlowCaseMap.case4_2_4;
    }
    return defaultEnergyFlow;
};
export const getEnergyFlowFormattedData = (data, t, locale = DEFAULT_LANGUAGE) => {
    if (isEmpty(data)) {
        return {
            [ENERGY_PRODUCT_TYPE.PV]: {
                pw: convertWattToKilowatt('W', 0.0, locale),
            },
            [ENERGY_PRODUCT_TYPE.LOAD]: {
                pw: convertWattToKilowatt('W', 0.0, locale),
            },
            [ENERGY_PRODUCT_TYPE.GRID]: { pw: convertWattToKilowatt('W', 0.0, locale), status: 'Grid' },
        };
    }
    return {
        [ENERGY_PRODUCT_TYPE.PV]: {
            pw: getFormattedPVValue(data.pv_pw, locale),
        },
        [ENERGY_PRODUCT_TYPE.LOAD]: {
            pw: convertWattToKilowatt('W', data.cons_pw, locale),
        },
        [ENERGY_PRODUCT_TYPE.GRID]: {
            pw: convertWattToKilowatt('W', data.grid_pw, locale),
            status: getGridStatus(data.grid_status ?? true, data.grid_pw, t),
        },
    };
};
const getGridStatus = (gridStatus, gridPower, t) => {
    if (!gridStatus)
        return 'Off-grid';
    if (gridPower > 0)
        return `${t('common.grid')}(${t('common.import')})`;
    if (gridPower < 0)
        return `${t('common.grid')}(${t('common.export')})`;
    return t('common.grid');
};
export const convertOperationModeToNumberString = (code) => {
    if (code?.startsWith('G3')) {
        const extractedOperationModeString = Number(code.slice(-2)).toString();
        const codeString = isEnergyPolicyType(extractedOperationModeString)
            ? extractedOperationModeString
            : null;
        return codeString;
    }
    return isEnergyPolicyType(code) ? code : null;
};
export const getNetworkConnectionStatusText = (networkStatus, disconnect) => {
    if (networkStatus === NETWORK_TYPE.UNKNOWN)
        return '';
    if (disconnect)
        return 'Disconnected';
    return 'Connected';
};
export const getNetworkImagePath = (networkStatus) => {
    switch (networkStatus) {
        case NETWORK_TYPE.ETHERNET:
            return NETWORK_TYPE_IMAGE.ETHERNET_IMAGE_PATH;
        case NETWORK_TYPE.WIFI:
            return NETWORK_TYPE_IMAGE.WIFI_IMAGE_PATH;
        case NETWORK_TYPE.LTE:
            return NETWORK_TYPE_IMAGE.LTE_IMAGE_PATH;
        default:
            return '';
    }
};
