export const SELECT_OPTION_LIST_EXPANDED_STATUS = {
    OPEN: 'open',
    HIDE: 'hide',
};
export const SELECTOR_VALUE_TYPE = {
    STRING: 'string',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
};
export const SELECTED_DISPLAY_TYPE = {
    TEXT: 'text',
    VALUE: 'value',
};
