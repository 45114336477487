import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { PopupWrapper } from '@hems/component';
import { TermsService } from '@hems/service';
import { TermsHelper, useLanguage, useLocale } from '@hems/util';
import { TERMS_TYPE, REQUIRED_TERMS_CATEGORY } from '@hems/util/src/constant';
export default defineComponent({
    name: 'PrivacyPolicyPopup',
    components: {
        PopupWrapper,
    },
    emits: ['close'],
    async setup(_props, { emit }) {
        const API_BASE_URL = process.env.VUE_APP_API_URL;
        const axiosInstance = axios.create({
            baseURL: API_BASE_URL,
        });
        const termsService = new TermsService(axiosInstance);
        const { locale } = useLocale();
        const { languageCode } = useLanguage();
        const termsList = ref([]);
        const privacyPolicyTerms = ref();
        const getTermsList = async () => {
            try {
                termsList.value = await termsService.getTerms(TERMS_TYPE.USER);
            }
            catch (e) {
                console.error(e);
            }
        };
        const requiredTerms = () => {
            const filteredTerms = TermsHelper.getFilteredTerms(termsList.value, locale, languageCode.value).requiredTerms;
            privacyPolicyTerms.value = filteredTerms[REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY];
        };
        const closePopup = () => {
            emit('close');
        };
        await getTermsList();
        requiredTerms();
        return {
            termsList,
            privacyPolicyTerms,
            closePopup,
        };
    },
});
