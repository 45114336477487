import { computed, defineComponent, reactive } from 'vue';
import * as moment from 'moment';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'VCalendarRange',
    components: {
        VCalendarWrapper,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: 'DD MMM, YYYY',
        },
        mode: {
            type: String,
            default: 'date',
        },
        minDate: Date,
        maxDate: Date,
        validate: {
            type: Function,
            default: () => false,
        },
    },
    emits: ['done'],
    setup(props, { emit }) {
        const state = reactive({
            open: false,
            disabled: computed(() => props.disabled),
            value: useModelWrapper(props, emit),
            displayStartValue: computed(() => {
                if (!props.modelValue.start)
                    return null;
                return props.format
                    ? moment.default(props.modelValue.start).format(props.format)
                    : moment.default(props.modelValue.start).locale(navigator.language).format('LLL');
            }),
            displayEndValue: computed(() => {
                if (!props.modelValue.end)
                    return null;
                return props.format
                    ? moment.default(props.modelValue.end).format(props.format)
                    : moment.default(props.modelValue.end).locale(navigator.language).format('LLL');
            }),
        });
        const dateMode = computed(() => (props.mode ? props.mode : 'datetime'));
        const onOpen = () => (state.open = true);
        const onClose = () => (state.open = false);
        const onDone = () => {
            emit('done');
        };
        return {
            state,
            dateMode,
            onOpen,
            onClose,
            onDone,
        };
    },
});
