import { defineComponent, computed, watch, ref } from 'vue';
import { GraphChart } from '@hems/component';
import { getTodayProductionChartOptions } from '@hems/container/src/energyflow/value/energyIndendenceChartOptions';
import { createChartData, getFormattedNumberValue } from '@hems/container/src/energyflow/value/EnergyValueFunctions';
import { useLocale } from '@hems/util';
import { UNIT } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
const CHART_HEIGHT = 240;
export default defineComponent({
    name: 'TodayProductionACNoConsumptionCT',
    components: {
        GraphChart,
    },
    props: {
        reportData: {
            type: Object,
            required: true,
        },
        showChart: {
            type: Boolean,
            default: false,
        },
        timezone: {
            type: String,
            default: getBrowserTimezone(),
        },
    },
    setup(props) {
        const { intlFormatLocale } = useLocale();
        const chartData = ref([]);
        const todayProduction = computed(() => {
            const formattedValue = getFormattedNumberValue(props.reportData?.gen_kwh.origin ?? 0, intlFormatLocale.value);
            return {
                origin: formattedValue.ori,
                formattedNumber: formattedValue.num,
                unit: props.reportData?.gen_kwh.unit ?? UNIT.WATT_HOUR,
            };
        });
        const options = computed(() => getTodayProductionChartOptions({ locale: intlFormatLocale.value, data: chartData.value, height: CHART_HEIGHT }));
        watch([() => props.showChart, () => props.reportData], () => {
            if (props.reportData) {
                chartData.value = createChartData(todayProduction.value.origin, props.reportData.gen_data, props.reportData.colec_time, props.timezone);
            }
        });
        return {
            todayProduction,
            options,
        };
    },
});
