import { defineComponent, onBeforeUnmount, onMounted, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { chart as HighchartsChart, Series } from 'highcharts';
import { every } from 'lodash-es';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';
import map from 'lodash-es/map';
import merge from 'lodash-es/merge';
import { Panel, BasicCheckbox } from '@hems/component';
import { GRAPH_Y_AXIS_UNIT, HIGHCHART_SERIES_COLOR, HIGHCHART_DIVIDING_LINE_COLOR, GRAPH_SERIES_TYPE, GRAPH_GROUP_CODE, } from '@hems/util/src/constant';
import { integratedGraphTooltipFormatter } from '@hems/util/src/helper/graphHelper';
import { capitalize } from '@hems/util/src/helper/helper';
import { getStringValue } from '@hems/util/src/helper/tsguardHelper';
const GRAPH_SECTION = {
    TOP: {
        HEIGHT: '48%',
    },
    MIDDLE: {
        POSITION: '50%',
        HEIGHT: '23%',
    },
    BOTTOM: {
        POSITION: '75%',
        HEIGHT: '23%',
    },
};
const GRAPH_SIZE = {
    WIDTH: 1160,
    HEIGHT: 1100,
};
export default defineComponent({
    name: 'IntegratedDeviceLineGraph',
    components: {
        Panel,
        BasicCheckbox,
    },
    props: {
        checkAll: {
            type: Boolean,
            default: () => true,
        },
        xAxisCategories: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
        checkboxFilterMap: {
            type: Object,
            default: () => ({
                [GRAPH_GROUP_CODE.POWER]: null,
                [GRAPH_GROUP_CODE.AC_COMBINER]: null,
                [GRAPH_GROUP_CODE.GRID]: null,
                [GRAPH_GROUP_CODE.BATTERY]: null,
                [GRAPH_GROUP_CODE.ENERGY]: null,
            }),
        },
    },
    setup(props) {
        const { t } = useI18n();
        const chart = ref(null);
        const chartInstance = ref(null);
        let crosshair = null;
        const isAllChecked = ref(props.checkAll);
        const initCategoryCheckedStatusByFilterMap = (filterList) => {
            const defaultCheckedStatusList = map(filterList, (filter) => filter.defaultChecked ?? false);
            return every(defaultCheckedStatusList, (checkedStatus) => checkedStatus);
        };
        const checkboxFilterMapList = ref(filter(map(props.checkboxFilterMap, (checkboxFilter) => ({
            title: checkboxFilter ? checkboxFilter.title : '',
            isCategoryChecked: checkboxFilter ? initCategoryCheckedStatusByFilterMap(checkboxFilter.filterList) : false,
            unit: checkboxFilter?.unit ?? '',
            filterList: checkboxFilter
                ? filter(checkboxFilter.filterList, (filter) => !filter.hidden).reduce((filterMap, filter) => {
                    filterMap[filter.code] = {
                        text: `${t(filter.name)}${filter.postfix ? ` (${filter.postfix})` : ''}
                  ${filter.unit ? ` [${filter.unit}]` : ''}`,
                        value: filter.code,
                        isChecked: props.checkAll ? true : filter.defaultChecked ?? false,
                    };
                    return filterMap;
                }, {})
                : null,
        })), (checkboxfilter) => !isNil(checkboxfilter.title) && !isNil(checkboxfilter.filterList)));
        const filterMapForEveryItem = computed(() => checkboxFilterMapList.value
            .map((checkboxFilter) => checkboxFilter.filterList)
            .filter((filterList) => !isNil(filterList))
            .reduce((filterMap, filter) => merge(filterMap, filter), {}));
        const updateSeriesVisibleStatus = (seriesId, isVisible) => {
            const series = chartInstance.value?.get(seriesId);
            if (!isNil(series) && series instanceof Series) {
                series.setVisible(isVisible, false);
            }
        };
        const onCheckAll = () => {
            isAllChecked.value = !isAllChecked.value;
            forEach(checkboxFilterMapList.value, (checkboxFilter) => {
                checkboxFilter.isCategoryChecked = isAllChecked.value;
                forEach(checkboxFilter.filterList, (filter) => {
                    filter.isChecked = isAllChecked.value;
                    updateSeriesVisibleStatus(filter.value ?? '', isAllChecked.value);
                });
            });
            chartInstance.value?.redraw();
        };
        const onCheckCategoryAll = (category) => {
            const categoryCheckedStatus = [];
            forEach(checkboxFilterMapList.value, (checkboxFilter) => {
                const isCategoryChecked = checkboxFilter.isCategoryChecked;
                if (checkboxFilter.title === category) {
                    categoryCheckedStatus.push(!isCategoryChecked);
                    forEach(checkboxFilter.filterList, (filter) => {
                        filter.isChecked = !isCategoryChecked;
                        updateSeriesVisibleStatus(filter.value ?? '', !isCategoryChecked);
                    });
                }
                else {
                    categoryCheckedStatus.push(isCategoryChecked);
                }
            });
            isAllChecked.value = every(categoryCheckedStatus, (checkedStatus) => checkedStatus);
            chartInstance.value?.redraw();
        };
        const onClickCheckbox = (checkedId) => {
            const categoryCheckedStatus = [];
            forEach(checkboxFilterMapList.value, (checkboxFilter) => {
                if (!isNil(checkboxFilter.filterList) && !isNil(checkboxFilter.filterList[checkedId])) {
                    checkboxFilter.filterList[checkedId].isChecked = !checkboxFilter.filterList[checkedId].isChecked;
                    updateSeriesVisibleStatus(checkedId, checkboxFilter.filterList[checkedId].isChecked);
                    checkboxFilter.isCategoryChecked = every(map(checkboxFilter.filterList, (filter) => filter.isChecked), (checkedStatus) => checkedStatus);
                }
                categoryCheckedStatus.push(checkboxFilter.isCategoryChecked);
            });
            isAllChecked.value = every(categoryCheckedStatus, (checkedStatus) => checkedStatus);
            chartInstance.value?.redraw();
        };
        const highchartSeriesColors = Object.values(HIGHCHART_SERIES_COLOR);
        const getOptions = (renderTo) => {
            return {
                credits: { enabled: false },
                title: { text: '' },
                colors: highchartSeriesColors,
                chart: {
                    type: GRAPH_SERIES_TYPE.SPLINE,
                    zoomType: 'x',
                    renderTo,
                    width: GRAPH_SIZE.WIDTH,
                    height: GRAPH_SIZE.HEIGHT,
                },
                boost: {
                    useGPUTranslations: true,
                },
                yAxis: [
                    {
                        id: GRAPH_Y_AXIS_UNIT.WATT,
                        title: {
                            text: `${t('device.power')} (${GRAPH_Y_AXIS_UNIT.WATT})`,
                        },
                        showEmpty: false,
                        lineWidth: 3,
                        lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
                        title: {
                            text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.WATT_HOUR})`,
                        },
                        min: 0,
                        showEmpty: false,
                        lineWidth: 3,
                        lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
                        offset: 0,
                        top: GRAPH_SECTION.BOTTOM.POSITION,
                        height: GRAPH_SECTION.BOTTOM.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR,
                        title: {
                            text: `${t('common.energy')} (${GRAPH_Y_AXIS_UNIT.KILOWATT_HOUR})`,
                        },
                        min: 0,
                        showEmpty: false,
                        lineWidth: 3,
                        lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
                        offset: 0,
                        top: GRAPH_SECTION.BOTTOM.POSITION,
                        height: GRAPH_SECTION.BOTTOM.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE,
                        title: {
                            text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE})`,
                        },
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE,
                        title: {
                            text: `Apparent Power (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE})`,
                        },
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
                        title: {
                            text: `${t('device.voltage')} (${GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE})`,
                        },
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.AMPERE,
                        title: {
                            text: `${t('device.current')} (${GRAPH_Y_AXIS_UNIT.AMPERE})`,
                        },
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.HERTZ,
                        title: {
                            text: `${t('device.frequency')} (${GRAPH_Y_AXIS_UNIT.HERTZ})`,
                        },
                        opposite: true,
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.CELSIUS,
                        title: {
                            text: `${t('device.temp')} (${GRAPH_Y_AXIS_UNIT.CELSIUS})`,
                        },
                        opposite: true,
                        showEmpty: false,
                        offset: 0,
                        top: GRAPH_SECTION.MIDDLE.POSITION,
                        height: GRAPH_SECTION.MIDDLE.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
                        title: {
                            text: `${t('common.percentage')} (${GRAPH_Y_AXIS_UNIT.PERCENTAGE})`,
                        },
                        showEmpty: false,
                        lineWidth: 3,
                        lineColor: HIGHCHART_DIVIDING_LINE_COLOR,
                        offset: 0,
                        top: GRAPH_SECTION.MIDDLE.POSITION,
                        height: GRAPH_SECTION.MIDDLE.HEIGHT,
                    },
                    {
                        id: GRAPH_Y_AXIS_UNIT.FACTOR,
                        title: {
                            text: capitalize(GRAPH_Y_AXIS_UNIT.FACTOR),
                        },
                        opposite: true,
                        showEmpty: false,
                        height: GRAPH_SECTION.TOP.HEIGHT,
                    },
                ],
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    series: {
                        lineWidth: 1,
                        turboThreshold: 2000,
                        events: {
                            legendItemClick: (evt) => {
                                const id = getStringValue(evt.target.userOptions.id);
                                if (isNil(id))
                                    return;
                                const visible = evt.target.visible;
                                const categoryCheckedStatus = [];
                                forEach(checkboxFilterMapList.value, (checkboxFilter) => {
                                    if (!isNil(checkboxFilter.filterList) && !isNil(checkboxFilter.filterList[id])) {
                                        checkboxFilter.filterList[id].isChecked = !visible;
                                        checkboxFilter.isCategoryChecked = every(map(checkboxFilter.filterList, (filter) => filter.isChecked), (checkedStatus) => checkedStatus);
                                    }
                                    categoryCheckedStatus.push(checkboxFilter.isCategoryChecked);
                                });
                                isAllChecked.value = every(categoryCheckedStatus, (checkedStatus) => checkedStatus);
                                return true;
                            },
                        },
                    },
                },
                series: [],
                tooltip: {
                    useHTML: true,
                    formatter() {
                        return integratedGraphTooltipFormatter(this);
                    },
                    positioner(labelWidth, labelHeight, point) {
                        crosshair?.destroy();
                        const chart = this.chart;
                        const container = chart.container;
                        const r = chart.renderer;
                        const top = chart.plotTop;
                        const left = chart.plotLeft;
                        const height = chart.plotHeight;
                        const x = point.plotX;
                        const y = point.plotY;
                        const chartWidth = chart.chartWidth;
                        crosshair = r
                            .path([
                            ['M', left + x, top],
                            ['L', left + x, top + height],
                        ])
                            .attr({
                            'stroke-width': 1,
                            stroke: HIGHCHART_DIVIDING_LINE_COLOR,
                        })
                            .add();
                        const maxY = container.offsetTop + container.offsetHeight / 2;
                        const plotY = container.offsetTop + y;
                        return {
                            x: chartWidth + container.offsetLeft,
                            y: plotY > maxY ? maxY : plotY,
                        };
                    },
                    outside: true,
                },
            };
        };
        const getOptionForUpdate = (xAxisCategories, data) => {
            const series = data.map((item) => {
                return {
                    ...item,
                    boostThreshold: 1,
                    turboThreshold: 1,
                    visible: filterMapForEveryItem.value[item.id]
                        ? !item.hidden && filterMapForEveryItem.value[item.id].isChecked
                        : false,
                    showInLegend: filterMapForEveryItem.value[item.id] ? !item.hidden : false,
                    type: GRAPH_SERIES_TYPE.SPLINE,
                };
            });
            return {
                xAxis: {
                    categories: xAxisCategories,
                    tickPositioner: () => {
                        const tickX = new Set([0]);
                        const regexp2hours = /(00|02|04|06|08|10|12|14|16|18|20|22|24):00$/g;
                        xAxisCategories.forEach((item, index) => {
                            if (regexp2hours.test(item))
                                tickX.add(index);
                        });
                        tickX.add(xAxisCategories.length - 1);
                        return Array.from(tickX);
                    },
                },
                series,
            };
        };
        const createChart = () => {
            if (!chart.value)
                return;
            const options = getOptions(chart.value);
            chartInstance.value = HighchartsChart(options);
        };
        const destroyChart = () => {
            if (chartInstance.value) {
                chartInstance.value.destroy();
                chartInstance.value = null;
            }
        };
        watch([() => props.xAxisCategories, () => props.data], () => {
            if (!chartInstance.value)
                return;
            const options = getOptionForUpdate(props.xAxisCategories, props.data);
            chartInstance.value?.update(options, true, true, false);
        });
        onMounted(() => {
            createChart();
        });
        onBeforeUnmount(() => {
            destroyChart();
        });
        return {
            chart,
            isAllChecked,
            checkboxFilterMapList,
            createChart,
            onCheckAll,
            onCheckCategoryAll,
            onClickCheckbox,
        };
    },
});
