import omit from 'lodash-es/omit';
import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import { Helper } from '@hems/util/src';
export default class RemoteControlServiceAdmin extends Service {
    constructor(axios) {
        super(axios);
    }
    async postControlToDevice(deviceId, params) {
        const convertedParams = this.getConvertedParams(params);
        if (!convertedParams) {
            return null;
        }
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/control/sync`), convertedParams);
        return data;
    }
    async postControlToSite(siteId, params) {
        const convertedParams = { target: 'site', ...this.getConvertedParams(params) };
        const { data } = await this.post(apiWrapper.controlApi(`/devices/site/${siteId}/control/sync`), convertedParams);
        return data;
    }
    getConvertedParams = (params) => {
        let convertedParams = Helper.convertSettingParam(params);
        if (params.cmd) {
            convertedParams = {
                cmd: params.cmd,
                data: omit(convertedParams, 'cmd'),
            };
        }
        return convertedParams || null;
    };
    // Control history 리스트
    async getControlHistList(deviceId, params) {
        const { data } = await this.get(apiWrapper.controlApi(`/devices/${deviceId}/control/list`), params);
        return data;
    }
}
