import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper, useModelWrapper } from '@hems/util';
import { INPUT_TYPE, INPUT_VALID_CLASS } from '@hems/util/src/constant/input';
import { getUUID } from '@hems/util/src/helper/helper';
import BasicInput from './BasicInput.vue';
export default defineComponent({
    name: 'AutoResizeInput',
    components: {
        BasicInput,
        ErrorMessageLabel,
    },
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number, null],
            default: null,
        },
        name: {
            type: String,
            default: getUUID(),
        },
        type: {
            type: String,
            default: INPUT_TYPE.TEXT,
        },
        validClass: {
            type: String,
            default: INPUT_VALID_CLASS.DEFAULT,
        },
        className: {
            type: String,
            default: 'fm_ipt',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const inputName = props.name ?? Helper.getUUID();
        const { value: inputValue, errorMessage } = useField(inputName, undefined, {
            initialValue: props.modelValue,
            type: props.type,
        });
        const inputModelValue = useModelWrapper(props, emit);
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue ?? null;
        });
        return {
            inputModelValue,
            inputValue,
            errorMessage,
            INPUT_TYPE,
        };
    },
});
