import { defineComponent, reactive, ref } from 'vue';
import Image from '@hems/component/src/image/Image.vue';
import { useLogout } from '@hems/util';
export default defineComponent({
    name: 'LoginUserInfo',
    components: {
        Image,
    },
    setup() {
        const { logout } = useLogout();
        const state = reactive({
            on: false,
        });
        const loginContainerEl = ref(null);
        function onToggle(e) {
            e.preventDefault();
            if (state.on)
                closeLogoutLayer();
            else
                openLogoutLayer();
        }
        function openLogoutLayer() {
            window.addEventListener('click', clickOtherLayer);
            state.on = true;
        }
        function closeLogoutLayer() {
            window.removeEventListener('click', clickOtherLayer);
            state.on = false;
        }
        function clickOtherLayer(e) {
            const target = e.target;
            if (target.parentElement !== loginContainerEl.value)
                closeLogoutLayer();
        }
        function onLogout(e) {
            e.preventDefault();
            logout();
        }
        return {
            state,
            loginContainerEl,
            onToggle,
            onLogout,
        };
    },
});
