/* eslint-disable import/prefer-default-export */
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { DateHelper, useSiteInfo } from '@hems/util';
import { HISTORY_INFO_CHANGE_TYPE_CODE } from '@hems/util/src/constant';
export const useInstallationHistory = () => {
    const store = useStore();
    const { t } = useI18n();
    const { masterDeviceProfile } = useSiteInfo();
    const allDeviceInstallHistoryList = store.state.siteNew.historyInfo ?? [];
    const allESSHistoryList = store.state.siteNew.essHistoryInfo ?? [];
    const allMiHistoryList = store.state.siteNew.siteInfoAC?.allMicroInverterHistoryInfoList ?? [];
    const hubHistoryList = store.state.siteNew.siteInfoAC?.hubHistoryInfoList ?? [];
    const hasInstallationHistoryInfo = computed(() => Boolean(allDeviceInstallHistoryList.filter((deviceHistoryList) => deviceHistoryList.length).length ||
        hubHistoryList.length ||
        allMiHistoryList.length ||
        allESSHistoryList.filter((deviceHistoryList) => deviceHistoryList.length).length));
    const hasACCombinerHistoryList = computed(() => allDeviceInstallHistoryList.length > 0);
    const hasHubHistoryList = computed(() => hubHistoryList.length > 0);
    const hasESSHistoryList = computed(() => allESSHistoryList.length > 0);
    const hasACModuleHistoryList = computed(() => allMiHistoryList.length > 0);
    const isNotDeleteChangedTypeCode = (history) => history.change_type_cd !== HISTORY_INFO_CHANGE_TYPE_CODE.DELETE;
    const isNotUpdateChangedTypeCode = (history) => history.change_type_cd !== HISTORY_INFO_CHANGE_TYPE_CODE.UPDATE;
    const getDeviceNumber = (deviceListLength, index) => {
        if (deviceListLength <= 1)
            return '';
        return index + 1;
    };
    const dateFormatConfig = {
        isTime: true,
        isSecond: false,
        needUtcConvert: true,
        needTimezoneConvert: true,
        timezone: masterDeviceProfile?.timezone_id,
    };
    const getFormattedDateString = (date) => DateHelper.getLocalDate(date, dateFormatConfig);
    const addTypeCountInfoToHistoryList = (historyList) => {
        let replaceCount = 0;
        let deleteCount = 0;
        return (historyList
            // TODO DELETE || UPDATE 타입 작업 시 아래 필터링 코드 제거
            .filter(isNotDeleteChangedTypeCode || isNotUpdateChangedTypeCode)
            .map((history) => {
            switch (history.change_type_cd) {
                case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE:
                case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE_MI:
                    replaceCount += 1;
                    break;
                case HISTORY_INFO_CHANGE_TYPE_CODE.DELETE:
                    deleteCount += 1;
                    break;
                default:
                    break;
            }
            return {
                ...history,
                replaceNum: replaceCount,
                deleteNum: deleteCount,
            };
        }));
    };
    const getChangedTypeCodeText = ({ change_type_cd: changeTypeCd, replaceNum, deleteNum, }) => {
        switch (changeTypeCd) {
            case HISTORY_INFO_CHANGE_TYPE_CODE.INSERT:
                return t('device.initial_installed');
            case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE:
            case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE_MI:
                return t('device.of_replaced', { num: replaceNum });
            // TODO DELETE는 추후 정의
            case HISTORY_INFO_CHANGE_TYPE_CODE.DELETE:
                return `Removed ${deleteNum}`;
            // TODO UPDATE는 기획 정의 후 개발 필요
            case HISTORY_INFO_CHANGE_TYPE_CODE.UPDATE:
                return '';
        }
    };
    return {
        allDeviceInstallHistoryList,
        allESSHistoryList,
        allMiHistoryList,
        hubHistoryList,
        hasInstallationHistoryInfo,
        hasACCombinerHistoryList,
        hasHubHistoryList,
        hasESSHistoryList,
        hasACModuleHistoryList,
        getDeviceNumber,
        getFormattedDateString,
        addTypeCountInfoToHistoryList,
        getChangedTypeCodeText,
        isNotDeleteChangedTypeCode,
        isNotUpdateChangedTypeCode,
    };
};
