import isNil from 'lodash-es/isNil';
import { NUMBER_UNIT, UNIT } from '@hems/util/src/constant';
import { BATTERY_STATUS_CODE } from '@hems/util/src/constant/remoteMonitoring';
class BatteryRenderer {
    batteryElement;
    batteryContainer;
    guage;
    value;
    constructor(props) {
        const batteryElement = $('<div class="fm_fcc"></div>');
        this.batteryContainer = $('<span class="state_battery"></span>');
        this.guage = $('<span class="in"></span>');
        this.value = $('<span class="state_val"></span>');
        batteryElement.append(this.batteryContainer);
        batteryElement.append(this.value);
        this.batteryContainer.append(this.guage);
        const emptyElement = $('<div></div>');
        this.batteryElement = isNil(props.value) ? emptyElement.get(0) : batteryElement.get(0);
        this.render(props);
    }
    getElement() {
        return this.batteryElement;
    }
    async render(props) {
        if (isNil(props.value))
            return;
        const BatteryStatusCode = props.grid.getValue(props.rowKey, 'bt_stus_cd');
        const batterySOC = Number(props.value);
        switch (BatteryStatusCode) {
            case BATTERY_STATUS_CODE.DISCHARGE:
                this.batteryContainer.addClass('ty_1');
                break;
            case BATTERY_STATUS_CODE.CHARGE:
                this.batteryContainer.addClass('ty_2');
                break;
            case BATTERY_STATUS_CODE.STANDBY:
                this.batteryContainer.addClass('ty_3');
                break;
        }
        const batterySOCWidth = batterySOC > NUMBER_UNIT.HUNDRED ? NUMBER_UNIT.HUNDRED : batterySOC;
        this.guage.css({ width: `${batterySOCWidth}${UNIT.PERCENT}` });
        const batterySOCPercentage = Math.round(batterySOC * NUMBER_UNIT.HUNDRED) / NUMBER_UNIT.HUNDRED;
        this.value.text(`${batterySOCPercentage}${UNIT.PERCENT}`);
    }
}
export default BatteryRenderer;
