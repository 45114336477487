import { defineComponent, computed, ref, onBeforeUnmount, watch, onMounted } from 'vue';
import { isNil } from 'lodash-es';
import { useField } from 'vee-validate';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import Tooltip from '@hems/component/src/tooltip/Tooltip.vue';
import { INPUT_VALID_CLASS, SELECTED_DISPLAY_TYPE, SELECTOR_VALUE_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Selector',
    components: {
        ErrorMessageLabel,
        Tooltip,
    },
    props: {
        modelValue: {
            type: [String, Number, Boolean, null],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        valueType: {
            type: String,
            default: SELECTOR_VALUE_TYPE.STRING,
        },
        className: {
            type: String,
            default: 'selecter_2',
        },
        callback: {
            type: Function,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        beforeChange: {
            type: Function,
            default: null,
        },
        afterChange: {
            type: Function,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedDisplayType: {
            type: String,
            default: SELECTED_DISPLAY_TYPE.TEXT,
        },
        isDisplaySelectedInList: {
            type: Boolean,
            default: true,
        },
        tooltipText: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        validClass: {
            type: String,
            default: INPUT_VALID_CLASS.DEFAULT,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const selector = ref(null);
        const isOpen = ref(false);
        const isFocus = ref(false);
        const transformValue = (value) => {
            if (isNil(value))
                return null;
            if (props.valueType === SELECTOR_VALUE_TYPE.BOOLEAN)
                return Boolean(value);
            if (props.valueType === SELECTOR_VALUE_TYPE.NUMBER)
                return Number(value);
            return value;
        };
        const { value: inputValue, errorMessage } = useField(props.name, undefined, {
            initialValue: transformValue(props.modelValue),
            type: props.valueType,
        });
        const selectorDisabled = computed(() => props.disabled);
        const selectorReadonly = computed(() => props.readonly);
        const selectorOptions = computed(() => props.options.map((item) => {
            const value = transformValue(item.value);
            return { ...item, value };
        }));
        const selected = computed(() => selectorOptions.value.find((item) => item.value === inputValue.value));
        const selectedText = computed(() => {
            if (selected.value && props.selectedDisplayType === SELECTED_DISPLAY_TYPE.VALUE) {
                return selected.value.value;
            }
            if (selected.value && props.selectedDisplayType === SELECTED_DISPLAY_TYPE.TEXT) {
                return selected.value.text;
            }
            return selectorOptions.value?.[0]?.text;
        });
        const hasDescription = computed(() => selectorOptions.value.some((option) => option.description));
        const onClickSelect = () => {
            if (!selectorDisabled.value && !selectorReadonly.value)
                isOpen.value = !isOpen.value;
        };
        const onClickOption = (value) => {
            if (props.beforeChange) {
                if (props.beforeChange(value.value, inputValue.value)) {
                    setValue(value);
                }
            }
            else {
                setValue(value);
            }
        };
        const onFocus = (focusStatus) => {
            isFocus.value = focusStatus;
        };
        const setValue = (value) => {
            inputValue.value = value.value;
            emit('update:modelValue', value.value, value.text);
            if (props.afterChange) {
                props.afterChange(value.value, value.text);
            }
        };
        const onClickOtherLayer = (e) => {
            if (e.target instanceof HTMLElement && e.target.parentElement !== selector.value) {
                isOpen.value = false;
            }
        };
        watch(() => props.modelValue, () => {
            if (inputValue.value !== props.modelValue)
                inputValue.value = props.modelValue ?? '';
        });
        onMounted(() => {
            document.addEventListener('click', onClickOtherLayer);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('click', onClickOtherLayer);
        });
        return {
            selected,
            selectedText,
            inputValue,
            selector,
            errorMessage,
            INPUT_VALID_CLASS,
            selectorDisabled,
            selectorOptions,
            isOpen,
            isFocus,
            selectorReadonly,
            hasDescription,
            onClickSelect,
            onClickOption,
            onFocus,
        };
    },
});
