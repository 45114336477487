import pickBy from 'lodash-es/pickBy';
import { AC_SYSTEM_FIELD_TYPE_CODE } from '@hems/util/src/constant/constant';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant/device';
import { SETTING_TYPE } from '@hems/util/src/constant/settings';
import { isNull, convertSettingParam } from '@hems/util/src/helper/helper';
import { getFilterValues } from '@hems/util/src/helper/pvmoduleSettingsHelper';
function getFcasSettingsColumns() {
    return [
        'fcas_flag',
        'fcas_response_capacity_limit',
        'fcas_droop_rate_lower',
        'fcas_droop_rate_raise',
        'fcas_fr_max',
        'fcas_fr_min',
        'fcas_nofb_max',
        'fcas_nofb_min',
        'fcas_oftb_max',
        'fcas_oftb_min',
    ];
}
export function getBasicSettingsColumns() {
    return [
        'pv_feed_in_limit_w',
        'energy_policy',
        'external_control_connection',
        'inverter_multiple_earthed_neutral_system',
        'pv_string_count',
        'pv_string_power1',
        'pv_string_power2',
        'pv_string_power3',
        'pv_feed_in_limit_w',
        'pv_capacity_calc_option',
    ];
}
export function getEngineerSettingsColumns() {
    return [
        'inverter_max_output_power',
        'inverter_grid_target_frequency',
        'inverter_external_generator_mode',
        'inverter_permit_service_mode_select',
        'inverter_enter_service_randomized_delay',
    ];
}
export function getInstallSettingColumns() {
    return [...getFcasSettingsColumns(), ...getBasicSettingsColumns(), ...getEngineerSettingsColumns()];
}
export function getFilterInstallSettingValues(data) {
    return pickBy(data, (v, k) => !isNull(v) && getInstallSettingColumns().includes(k));
}
// @TODO: 장비 타입 관계 없이 공용으로 사용할 수 있도록 개선
export function getSettingSaveParams(data, target) {
    let params = {
        msgType: data.msg_type,
        target,
    };
    if (data.msg_type.includes(SETTING_TYPE.BASIC)) {
        params = { ...params, ...convertSettingParam(getFilterValues(GEN_TYPE.AC_SYS, SETTING_TYPE.BASIC, data)) };
    }
    if (data.msg_type.includes(SETTING_TYPE.ADVANCED)) {
        const ACSystemAdvancedParams = { advanced_setting: [] };
        data.advanced_setting?.forEach((item, idx) => {
            const advancedSettingParams = convertSettingParam(getFilterValues(GEN_TYPE.AC_SYS, SETTING_TYPE.ADVANCED, item));
            const installSettingParams = convertSettingParam(getFilterInstallSettingValues(item));
            const productModelNm = convertSettingParam({ product_model_nm: item.product_model_nm });
            ACSystemAdvancedParams.advanced_setting[idx] = {
                ...advancedSettingParams,
                ...installSettingParams,
                ...productModelNm,
            };
        });
        params = { ...params, ...ACSystemAdvancedParams };
    }
    if (data.msg_type.includes(SETTING_TYPE.TOU)) {
        params = { ...params, ...convertSettingParam(getFilterValues(GEN_TYPE.AC_SYS, SETTING_TYPE.TOU, data)) };
    }
    if (data.msg_type.includes(SETTING_TYPE.ENGINEER)) {
        params = { ...params, ...convertSettingParam(getFilterValues(GEN_TYPE.AC_SYS, SETTING_TYPE.ENGINEER, data)) };
    }
    return params;
}
/** control, setting의 DeviceTab의 value 값을 통해 control, setting을 내릴 target Id를 가져오는 함수
 * @param fieldType DeviceTab의 dropdown value
 * @param deviceList
 * @returns
 */
export const getDeviceIdFromDeviceTabValueForAC = (fieldType, deviceList) => {
    if (fieldType === AC_SYSTEM_FIELD_TYPE_CODE.ACCB) {
        return (deviceList.find(({ deviceInfo }) => deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER)?.deviceInfo
            .device_id ?? null);
    }
    if (fieldType.startsWith(AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL)) {
        const replaceText = `${AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL}_`;
        return fieldType.replace(replaceText, '');
    }
    return null;
};
