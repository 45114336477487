/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useSiteDeviceGenType } from '@hems/util/src/composable/site';
import { AC_DEVICE_TYPE, EMS_TYPE } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
import { isACSystemType, isDeviceACType } from '@hems/util/src/helper/tsguardHelper';
// eslint-disable-next-line import/prefer-default-export
export const useSiteInfo = () => {
    const store = useStore();
    const inventoryInfo = store.state.siteNew.inventoryInfo;
    const siteInfoFOX = store.state.siteNew.siteInfoFOX;
    const siteInfoAC = store.state.siteNew.siteInfoAC;
    const siteInfoAU = store.state.siteNew.siteInfoAU;
    const hubInfo = store.state.siteNew.hubInfo;
    const deviceList = store.state.siteNew.deviceList;
    const getACDeviceProfileList = () => {
        const deviceProfile = deviceList.map((device) => device.deviceInfo);
        /** @TODO map & filter 사용으로 개선 예정 */
        return deviceProfile.reduce((ACTypeGardProfileList, profile) => {
            if (isACSystemType(profile)) {
                ACTypeGardProfileList.push(profile);
            }
            return ACTypeGardProfileList;
        }, []);
    };
    const ACDeviceList = computed(() => deviceList.filter((device) => isDeviceACType(device)));
    /** TS Version 5 에서는 타입 가드가 filter에서도 동작하지만,
     * 프로젝트 TS Version 4 에서는 filter로 타입 가드한 값을 가져다 사용했을 때 TS 에러 발생하여 getACDeviceList 정의 */
    const getACDeviceList = () => {
        const ACDeviceList = [];
        deviceList.map((device) => {
            if (isDeviceACType(device)) {
                ACDeviceList.push(device);
            }
        });
        return ACDeviceList;
    };
    const getACPrimaryDeviceId = () => {
        let ACCombinerId = '';
        let ACCoupledPrimaryId = '';
        const ACDeviceList = getACDeviceList();
        ACDeviceList.forEach((device) => {
            if (device.deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER) {
                ACCombinerId = device.deviceInfo.device_id;
            }
            else if (device.deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_ESS &&
                device.deviceInfo.ems_type_cd === EMS_TYPE.PRIMARY) {
                ACCoupledPrimaryId = device.deviceInfo.device_id;
            }
        });
        return { ACCombinerId, ACCoupledPrimaryId };
    };
    const { isAC } = useSiteDeviceGenType();
    const [masterDeviceInfo] = deviceList.map((device) => device);
    const batteryInfoOfMasterDevice = {
        batteryPackInfoList: masterDeviceInfo.batteryInfo,
    };
    const masterDeviceProfile = isAC
        ? store.state.siteNew.siteInfoAC?.masterACDeviceProfile
        : masterDeviceInfo.deviceInfo;
    /** Site 내의 AC Combiner Profile 조회 */
    const ACCombinerProfile = getACDeviceProfileList().find((profile) => profile.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER);
    /** Site 내의 모든 ESS Profile 조회 (1개 이상일 수 있음) */
    const ACESSProfileList = getACDeviceProfileList().filter((device) => device.device_type_cd === AC_DEVICE_TYPE.AC_ESS);
    /** Site 내의 ESS 개수 */
    const ESSCount = ACESSProfileList.length;
    /** site timezone ID */
    const timezoneId = masterDeviceProfile?.timezone_id ?? getBrowserTimezone();
    const essDeviceList = computed(() => getACDeviceList().filter((device) => device.deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_ESS));
    return {
        masterDeviceProfile,
        inventoryInfo,
        hubInfo,
        siteInfoFOX,
        siteInfoAC,
        siteInfoAU,
        getACDeviceProfileList,
        ACCombinerProfile,
        batteryInfoOfMasterDevice,
        ACDeviceList,
        getACDeviceList,
        getACPrimaryDeviceId,
        ESSCount,
        ACESSProfileList,
        timezoneId,
        essDeviceList,
    };
};
