import isEmpty from 'lodash-es/isEmpty';
import * as yup from 'yup';
function isHeader(item) {
    return item.type === 'header';
}
export function getEditableSettingConfig(settingConfig, editableColumns = [], excludedColumns = []) {
    return settingConfig.reduce((acc, curr) => {
        const children = curr.children.filter((item) => isHeader(item)
            ? false
            : editableColumns.includes(String(item.code)) && !excludedColumns.includes(String(item.code)));
        if (children.length > 0) {
            acc.push({ ...curr, ...{ canEdit: true, children } });
        }
        return acc;
    }, []);
}
export function getSchema(advancedSettingConfig) {
    const shape = advancedSettingConfig
        .map((group) => group.children
        .map((item) => (item.type === 'number' || item.type === 'selector' || item.type === 'toggle') && item.schema
        ? { [item.code]: item.schema() }
        : {})
        .flat())
        .flat()
        .reduce((acc, curr) => {
        return { ...acc, ...curr };
    }, {});
    return yup.object().shape(shape);
}
export function getACSystemSchema(advancedSettingConfig) {
    const shape = advancedSettingConfig
        .map((group) => group.children
        .map((item) => (item.type === 'number' || item.type === 'selector' || item.type === 'toggle') && item.schema && item.name
        ? { [item.name]: item.schema() }
        : {})
        .flat())
        .flat()
        .reduce((acc, curr) => {
        return { ...acc, ...curr };
    }, {});
    return yup.object().shape(shape);
}
export function installSettingGen3Config(t, options = { energyPolicy: [], extctlGwConnection: [] }, excludedGroupTitle = []) {
    return [
        {
            groupTitle: 'FCAS',
            role: ['admin', 'service', 'dev'],
            children: [
                {
                    title: 'FCAS Flag',
                    code: 'fcas_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
                {
                    title: 'Response Capacity limit',
                    code: 'fcas_response_capacity_limit',
                    type: 'number',
                },
                {
                    title: 'Droop Rate Raise',
                    code: 'fcas_droop_rate_raise',
                    type: 'number',
                },
                {
                    title: 'Droop Rate Lower',
                    code: 'fcas_droop_rate_lower',
                    type: 'number',
                },
                {
                    title: 'Oftb Max',
                    code: 'fcas_oftb_max',
                    type: 'number',
                },
                {
                    title: 'Oftb Min',
                    code: 'fcas_oftb_min',
                    type: 'number',
                },
                {
                    title: 'Fr Max',
                    code: 'fcas_fr_max',
                    type: 'number',
                },
                {
                    title: 'Fr Min',
                    code: 'fcas_fr_min',
                    type: 'number',
                },
                {
                    title: 'Nofb Max',
                    code: 'fcas_nofb_max',
                    type: 'number',
                },
                {
                    title: 'Nofb Min',
                    code: 'fcas_nofb_min',
                    type: 'number',
                },
            ],
        },
        {
            groupTitle: 'Basic Setting',
            role: ['admin', 'installer', 'service', 'dev'],
            children: [
                {
                    title: t('control.external_control'),
                    code: 'external_control_connection',
                    type: 'selector',
                    selector: options.extctlGwConnection,
                },
                {
                    title: t('control.energy_policy'),
                    code: 'energy_policy',
                    type: 'selector',
                    selector: options.energyPolicy,
                },
                {
                    title: 'Multiple Earthed Neutral(MEN) system',
                    code: 'inverter_multiple_earthed_neutral_system',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
                {
                    title: `${t('device.string_level')} / ${t('device.module_level')}`,
                    code: 'pv_capacity_calc_option',
                    type: 'radio',
                    options: [
                        { text: t('device.string_level'), value: 0 },
                        { text: t('device.module_level'), value: 1 },
                    ],
                },
                {
                    title: t('device.pv_string_quantity'),
                    code: 'pv_string_count',
                    type: 'selector',
                    options: [
                        { text: t('common.select'), value: null },
                        { text: '1', value: 1 },
                        { text: '2', value: 2 },
                        { text: '3', value: 3 },
                        { text: '4', value: 4 },
                        { text: '5', value: 5 },
                    ],
                },
                {
                    title: `PV-1 ${t('device.power')}`,
                    code: 'pv_string_power1',
                    type: 'number',
                },
                {
                    title: `PV-2 ${t('device.power')}`,
                    code: 'pv_string_power2',
                    type: 'number',
                },
                {
                    title: `PV-3 ${t('device.power')}`,
                    code: 'pv_string_power3',
                    type: 'number',
                },
            ],
        },
        {
            groupTitle: 'Engineer Setting',
            role: ['admin', 'service', 'dev'],
            children: [
                {
                    title: 'Inverter limit',
                    code: 'inverter_max_output_power',
                    type: 'number',
                },
                {
                    title: 'Grid target frequency',
                    code: 'inverter_grid_target_frequency',
                    type: 'number',
                },
                {
                    title: 'External generator mode',
                    code: 'inverter_external_generator_mode',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
            ],
        },
    ].filter((item) => !excludedGroupTitle.includes(item.groupTitle));
}
export function installSettingACMIConfig(t, options = { energyPolicy: [], extctlGwConnection: [] }, excludedGroupTitle = []) {
    return [
        {
            groupTitle: 'Basic Setting',
            role: ['admin', 'installer', 'service', 'dev'],
            children: [
                {
                    title: `${t('control.feedin')} (W)`,
                    code: 'pv_feed_in_limit_w',
                    type: 'number',
                },
                {
                    title: t('control.energy_policy'),
                    code: 'energy_policy',
                    type: 'selector',
                    selector: options.energyPolicy,
                },
            ],
        },
        {
            groupTitle: 'Engineer Setting',
            role: ['admin', 'service', 'dev'],
            children: [
                {
                    title: 'Grid target frequency',
                    code: 'inverter_grid_target_frequency',
                    type: 'number',
                },
                {
                    title: 'Permit service mode select flag',
                    code: 'inverter_permit_service_mode_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
                {
                    title: 'Enter service randomized delay flag',
                    code: 'inverter_enter_service_randomized_delay',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
            ],
        },
    ].filter((item) => !excludedGroupTitle.includes(item.groupTitle));
}
export function installSettingACESConfig(t, options = { energyPolicy: [], extctlGwConnection: [] }, excludedGroupTitle = []) {
    return [
        {
            groupTitle: 'Basic Setting',
            role: ['admin', 'installer', 'service', 'dev'],
            children: [
                {
                    title: `${t('control.feedin')} (W)`,
                    code: 'pv_feed_in_limit_w',
                    type: 'number',
                },
                {
                    title: t('control.energy_policy'),
                    code: 'energy_policy',
                    type: 'selector',
                    selector: options.energyPolicy,
                },
                {
                    title: t('device.pv_string_quantity'),
                    code: 'pv_string_count',
                    type: 'selector',
                    options: [
                        { text: t('common.select'), value: null },
                        { text: '1', value: 1 },
                        { text: '2', value: 2 },
                        { text: '3', value: 3 },
                        { text: '4', value: 4 },
                        { text: '5', value: 5 },
                    ],
                },
                {
                    title: `PV-1 ${t('device.power')}`,
                    code: 'pv_string_power1',
                    type: 'number',
                },
                {
                    title: `PV-2 ${t('device.power')}`,
                    code: 'pv_string_power2',
                    type: 'number',
                },
                {
                    title: `PV-3 ${t('device.power')}`,
                    code: 'pv_string_power3',
                    type: 'number',
                },
            ],
        },
        {
            groupTitle: 'Engineer Setting',
            role: ['admin', 'service', 'dev'],
            children: [
                {
                    title: 'Inverter limit',
                    code: 'inverter_max_output_power',
                    type: 'number',
                },
                {
                    title: 'Grid target frequency',
                    code: 'inverter_grid_target_frequency',
                    type: 'number',
                },
                {
                    title: 'Permit service mode select flag',
                    code: 'inverter_permit_service_mode_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
                {
                    title: 'Enter service randomized delay flag',
                    code: 'inverter_enter_service_randomized_delay',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                },
            ],
        },
    ].filter((item) => !excludedGroupTitle.includes(item.groupTitle));
}
export function getIncludedSettingConfig(advancedSettingConfig, excludedColumns = []) {
    return advancedSettingConfig.reduce((acc, curr) => {
        const children = curr.children.filter((item) => isHeader(item) ? false : !excludedColumns.includes(String(item.code)));
        if (children.length > 0) {
            acc.push(Object.assign({}, curr, { canEdit: true, children }));
        }
        return acc;
    }, []);
}
export function getRangeDescription(item) {
    let description = '';
    if (item.type === 'number') {
        if (item.description) {
            return item.description;
        }
        description = `${item.min} ~ ${item.max} ${item.unit ? item.unit : ''}`;
    }
    return description;
}
export function getFormattedData(data) {
    const result = {};
    Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'number') {
            result[key] = Number(value.toFixed(3));
        }
        else {
            result[key] = value;
        }
    });
    return result;
}
export function getSettingValue(item, confirmItems) {
    const valueType = item.type;
    switch (valueType) {
        case 'number':
        case 'text':
            return confirmItems[String(item.code)] || null;
        case 'toggle':
            return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
        case 'radio':
            return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
        case 'selector':
            return item.options?.filter((option) => option.value == confirmItems[String(item.code)])[0]?.text || null;
        default:
            return null;
    }
}
export function getValidator(min, max) {
    const numberValidator = () => yup
        .number()
        .transform((v, o) => (o === '' ? null : v))
        .nullable();
    const exponent = Math.max(min.split('.')[1]?.length ?? 0, max.split('.')[1]?.length ?? 0);
    const unitNumber = 1 / 10 ** exponent;
    return numberValidator().min(Number(min)).max(Number(max)).unitNumber(unitNumber);
}
/**
 *
 * @param gridCode
 * @param defaultConfig [advancedSettingACSystemConfig] grid_code 별 설정된 기본 항목을 보여주는 항목
 * @param rangeUnitInfo [column_range] grid_code 별 설정하는 항목에 대한 정보 (col_nm, min, max, unit)
 * @returns
 */
export function getUpdateConfigByServer(gridCode, defaultConfig, rangeUnitInfo) {
    const rangeUnitInfoByGrid = rangeUnitInfo.filter((config) => config.grid_code === gridCode);
    // grid_code : 0 -> EDM 기준의 range
    const rangeUnitInfoByDefault = rangeUnitInfo.filter((config) => config.grid_code === 0);
    // defaultConfig(advancedSettingACESConfig, advancedSettingACCombinerConfig) 의 각 group에 대해 반복
    const updatedConfig = defaultConfig.map((group) => {
        // group의 children을 반복
        const children = group.children.map((child) => {
            // children 하나의 min, max range를 가져옴
            const range = rangeUnitInfoByGrid.find((config) => config.col_nm === child.code) ||
                rangeUnitInfoByDefault.find((config) => config.col_nm === child.code);
            // 장비 최초 설치하여 grid code 별로 설정하지 않았을 경우에만 default config의 range를 사용
            // 항상 서버에서 주는 grid code 별 range 를 사용
            if (!isEmpty(range)) {
                const target = range;
                if (child.type === 'number') {
                    return {
                        ...child,
                        min: target.range_min,
                        max: target.range_max,
                        unit: target.range_unit && target.range_unit !== '-' ? target.range_unit : child.unit,
                        schema: () => getValidator(target.range_min, target.range_max),
                    };
                }
            }
            return child;
        });
        return {
            ...group,
            children,
        };
    });
    return updatedConfig;
}
