/* eslint-disable import/prefer-default-export */
const SITE_INFO_SECTION_ID_PREFIX = 'site-info';
const INSTALLATION_HISTORY_SECTION_ID_PREFIX = 'installation-history';
export const SITE_INFO_SECTION_ID = {
    AC_COMBINER: `${SITE_INFO_SECTION_ID_PREFIX}-ac-combiner`,
    SOLAR: `${SITE_INFO_SECTION_ID_PREFIX}-solar`,
    ESS: `${SITE_INFO_SECTION_ID_PREFIX}-ess`,
    HUB: `${SITE_INFO_SECTION_ID_PREFIX}-hub`,
    GRID: `${SITE_INFO_SECTION_ID_PREFIX}-grid`,
    LOAD: `${SITE_INFO_SECTION_ID_PREFIX}-load`,
    INSTALLATION_HISTORY: `${INSTALLATION_HISTORY_SECTION_ID_PREFIX}`,
    INSTALLATION_HISTORY_AC_COMBINER: `${INSTALLATION_HISTORY_SECTION_ID_PREFIX}-ac-combiner`,
    INSTALLATION_HISTORY_HUB: `${INSTALLATION_HISTORY_SECTION_ID_PREFIX}-hub`,
    INSTALLATION_HISTORY_ESS: `${INSTALLATION_HISTORY_SECTION_ID_PREFIX}-ess`,
    INSTALLATION_HISTORY_AC_MODULE: `${INSTALLATION_HISTORY_SECTION_ID_PREFIX}-ac-module`,
    INTERNET_CONNECTION: 'internet-connection',
};
