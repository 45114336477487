import isNil from 'lodash-es/isNil';
import * as yup from 'yup';
import { Helper } from '@hems/util';
import { BACKUP_TYPE, CONSUMPTION_CT_TYPE, MPU_AVOIDANCE, NEC_120_RULE_RATE, UNIT } from '@hems/util/src/constant';
import { AC_SYSTEM_FIELD_TYPE_CODE } from '@hems/util/src/constant/constant';
export const isSettingField = (fieldName, fieldList, tabName, target) => {
    if (typeof target === 'string') {
        if (target.startsWith(AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL)) {
            target = target?.replace(/_([^_]+)$/, '');
        }
        const fieldItem = tabName === AC_SYSTEM_FIELD_TYPE_CODE.SITE
            ? { name: fieldName, type: AC_SYSTEM_FIELD_TYPE_CODE.SITE }
            : { name: fieldName, type: target };
        return fieldList.some((field) => field.name === fieldItem.name && field.type.includes(fieldItem.type));
    }
    return false;
};
export const settingTableRerender = (tableName) => {
    const tableList = document.getElementsByClassName(tableName);
    Array.prototype.forEach.call(tableList, (element) => {
        const table = element.getElementsByTagName('table')[0];
        if (table.rows.length === 0) {
            element.style.display = 'none';
        }
        else {
            element.style.display = 'block';
        }
    });
};
export const getTargetList = (data) => {
    const childFieldTargetInfo = [];
    data.forEach((group) => {
        group.children.forEach((child) => {
            childFieldTargetInfo.push({
                name: child.code,
                type: child.target,
            });
        });
    });
    return childFieldTargetInfo;
};
const acSystemGridCodePair = [
    { accb: 8451, aces: 8401 },
    { accb: 8452, aces: 8402 },
    { accb: 8453, aces: 8403 },
    { accb: 8454, aces: 8404 },
    { accb: 8455, aces: 8406 },
];
export const createGridCodePairedMap = (gridCodePair) => {
    const gridCodePairedMap = gridCodePair.reduce((acc, pair) => {
        acc[pair.accb] = { accb: pair.accb, aces: pair.aces };
        acc[pair.aces] = { accb: pair.accb, aces: pair.aces };
        return acc;
    }, {});
    return gridCodePairedMap;
};
export const ACSystemGridCodePair = createGridCodePairedMap(acSystemGridCodePair);
export const getDERBreakerRatingMax = (busbarRating, breakerRating) => Math.floor(NEC_120_RULE_RATE * busbarRating - breakerRating);
const defaultValidate = () => yup
    .number()
    .transform((v) => (Helper.isNull(v) || isNaN(v) ? null : v))
    .nullable();
export const acSystemComputedRange = {
    valueRange: {
        pv_feed_in_limit: { min: 0, max: 100 },
        pv_max_pwr2: { min: 0, max: 3300 },
        feed_in_limit: { min: 0, max: 100 },
        battery_backup_soc: { min: 0, max: 100 },
        battery_installed_rack_count: { min: 0, max: 3 },
        pv_per: { min: 0, max: 65535 },
        pv_module_count: { min: 0, max: 100 },
        pv_module_power: { min: 0, max: 1000 },
    },
};
export const getBackupTypeOptionList = (t) => [
    { text: t('common.none'), value: BACKUP_TYPE.NONE, disabled: true },
    { text: t('control.whole_home_backup'), value: BACKUP_TYPE.WHOLE_HOME_BACKUP },
    { text: t('control.partial_home_backup'), value: BACKUP_TYPE.PARTIAL_HOME_BACKUP },
];
export const getBasicSettingACSystemConfigR05 = (t, data, isBackfeedLimitActivated, isVersionAbove4) => {
    return [
        {
            groupTitle: t('control.site_configuration'),
            type: 'table',
            children: [
                {
                    title: t('control.backup_type'),
                    code: 'site_info_backup_config_type',
                    type: 'selector',
                    options: getBackupTypeOptionList(t),
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('site_info_backup_config_type', (value, context) => {
                        const { createError } = context;
                        /** Backup type을 none으로 설정 불가 */
                        if (value === BACKUP_TYPE.NONE) {
                            return createError({ message: { key: 'message.invalid_value' } });
                        }
                        return true;
                    }),
                },
                {
                    title: t('control.mpu_avoidance'),
                    code: 'accb_backfeed_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.none'), value: MPU_AVOIDANCE.NONE },
                        { text: t('control.backfeed_limit'), value: MPU_AVOIDANCE.BACKFEED_LIMIT },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('control.backfeed_limit'),
                    code: 'accb_backfeed_limit_option',
                    type: 'radio',
                    options: [
                        { value: 0, text: t('control.enter_directly') },
                        { value: 1, text: t('control.nec_120_rule') },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('control.direct_input'),
                    code: 'accb_backfeed_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().when({
                        is: () => isBackfeedLimitActivated && data.accb_backfeed_limit_flag && !data.accb_backfeed_limit_option,
                        then: defaultValidate().integer().min(1).max(210).default(32).required(),
                    }),
                },
                {
                    title: t('control.panel_busbar_rating'),
                    code: 'msp_busbar_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().when({
                        is: () => isBackfeedLimitActivated && data.accb_backfeed_limit_flag && data.accb_backfeed_limit_option,
                        then: defaultValidate().integer().min(2).max(1000).default(200).required(),
                    }),
                },
                {
                    title: t('control.breaker_rating'),
                    code: 'msp_breaker_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().when({
                        is: () => isBackfeedLimitActivated && data.accb_backfeed_limit_flag && data.accb_backfeed_limit_option,
                        then: defaultValidate().integer().min(1).max(1000).default(200).required(),
                    }),
                },
            ],
        },
        {
            groupTitle: t('common.solar'),
            type: 'table',
            children: [
                {
                    title: t('control.3rd_party_pv_output'),
                    code: 'pv_inverter_nameplate_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => defaultValidate().integer().min(1).max(19200),
                },
            ],
        },
        {
            groupTitle: t('device.ess'),
            type: 'table',
            children: [
                {
                    title: t('control.energy_backup'),
                    code: 'battery_backup_soc',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                },
            ],
        },
        {
            groupTitle: t('device.grid'),
            type: 'table',
            children: [
                {
                    title: t('control.feed_in_limit_flag'),
                    code: 'pv_feed_in_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
                {
                    title: t('control.feedin_limit'),
                    code: 'pv_feed_in_limit_w',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => defaultValidate().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: t('device.measurement'),
            type: 'table',
            children: [
                {
                    title: t('device.consumption_ct'),
                    code: isVersionAbove4 ? 'accb_consumption_ct_installed_point' : 'accb_external_ct_installed_point',
                    type: 'selector',
                    options: [
                        {
                            text: t('common.not_installed'),
                            value: CONSUMPTION_CT_TYPE.NOT_INSTALLED,
                        },
                        { text: t('common.grid_side'), value: CONSUMPTION_CT_TYPE.GRID_SIDE },
                        { text: t('common.load_side'), value: CONSUMPTION_CT_TYPE.LOAD_SIDE },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().nullable().notRequired(),
                },
                {
                    title: t('device.external_production_ct'),
                    code: 'accb_extended_production_ct_flag',
                    type: 'radio',
                    options: [
                        { text: t('common.installed'), value: 1 },
                        { text: t('common.not_installed'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().nullable().notRequired(),
                },
                {
                    title: t('device.production_meter'),
                    code: 'accb_polarity_reverse_production_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.external_production_meter'),
                    code: 'accb_polarity_reverse_consumption_l1_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.consumption_meter_l1'),
                    code: 'accb_polarity_reverse_consumption_l2_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.consumption_meter_l2'),
                    code: 'accb_polarity_reverse_ext_production_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
            ],
        },
    ];
};
const createErrorSchema = (context, minValue, maxValue, value) => {
    const { createError } = context;
    if (isNil(value))
        return createError({ message: { key: 'message.field_required' } });
    if (value < minValue || value > maxValue) {
        return createError({ message: { key: 'message.enter_within_range' } });
    }
    return true;
};
export const getBasicSettingACSystemConfig = (t, data, isBackfeedLimitDeactivated, isGridSupportConfig) => {
    return [
        {
            groupTitle: t('control.site_configuration'),
            type: 'table',
            children: [
                {
                    title: t('control.backup_type'),
                    code: 'site_info_backup_config_type',
                    type: 'selector',
                    options: getBackupTypeOptionList(t),
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('site_info_backup_config_type', (value, context) => {
                        const { createError } = context;
                        /** Backup type을 none으로 설정 불가 */
                        if (value === BACKUP_TYPE.NONE) {
                            return createError({ message: { key: 'message.invalid_value' } });
                        }
                        return true;
                    }),
                },
                {
                    title: t('control.mpu_avoidance'),
                    code: 'msp_busbar_protection_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.none'), value: MPU_AVOIDANCE.NONE },
                        { text: t('control.backfeed_limit'), value: MPU_AVOIDANCE.BACKFEED_LIMIT },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
                {
                    title: t('control.backfeed_limit'),
                    code: 'backfeed_limit_option',
                    type: 'radio',
                    options: [{ value: 1, text: t('control.nec_120_rule') }],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
                {
                    title: t('control.breaker_rating'),
                    code: 'msp_msp_breaker_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('msp_msp_breaker_rating', (value, context) => {
                        if (isBackfeedLimitDeactivated ||
                            !data.msp_busbar_protection_flag ||
                            !data.backfeed_limit_option ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_breaker_rating)) {
                            return true;
                        }
                        return createErrorSchema(context, 1, 1000, value);
                    }),
                },
                {
                    title: t('control.panel_busbar_rating'),
                    code: 'msp_msp_busbar_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('msp_msp_busbar_rating', (value, context) => {
                        if (isBackfeedLimitDeactivated ||
                            !data.msp_busbar_protection_flag ||
                            !data.backfeed_limit_option ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_breaker_rating)) {
                            return true;
                        }
                        return createErrorSchema(context, 2, 1000, value);
                    }),
                },
                {
                    title: t('control.der_breaker_rating'),
                    code: 'msp_der_breaker_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('msp_der_breaker_rating', (value, context) => {
                        if (isBackfeedLimitDeactivated ||
                            !data.msp_busbar_protection_flag ||
                            !data.backfeed_limit_option ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_breaker_rating) ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_busbar_rating)) {
                            return true;
                        }
                        const DERBreakerRatingMax = getDERBreakerRatingMax(context.parent.msp_msp_busbar_rating, context.parent.msp_msp_breaker_rating);
                        return createErrorSchema(context, 1, DERBreakerRatingMax, value);
                    }),
                },
                {
                    title: t('control.msp_pv_breaker_rating'),
                    code: 'msp_pv_breaker_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('msp_pv_breaker_rating', (value, context) => {
                        if (!isGridSupportConfig ||
                            isBackfeedLimitDeactivated ||
                            !data.msp_busbar_protection_flag ||
                            !data.backfeed_limit_option ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_breaker_rating) ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_busbar_rating)) {
                            return true;
                        }
                        const DERBreakerRatingMax = getDERBreakerRatingMax(context.parent.msp_msp_busbar_rating, context.parent.msp_msp_breaker_rating);
                        return createErrorSchema(context, 1, DERBreakerRatingMax, value);
                    }),
                },
                {
                    title: t('control.msp_ess_breaker_rating'),
                    code: 'msp_ess_breaker_rating',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => yup.mixed().test('msp_ess_breaker_rating', (value, context) => {
                        if (!isGridSupportConfig ||
                            isBackfeedLimitDeactivated ||
                            !data.msp_busbar_protection_flag ||
                            !data.backfeed_limit_option ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_breaker_rating) ||
                            !yup.number().required().isValidSync(context.parent.msp_msp_busbar_rating)) {
                            return true;
                        }
                        const DERBreakerRatingMax = getDERBreakerRatingMax(context.parent.msp_msp_busbar_rating, context.parent.msp_msp_breaker_rating);
                        return createErrorSchema(context, 1, DERBreakerRatingMax, value);
                    }),
                },
            ],
        },
        {
            groupTitle: t('common.solar'),
            type: 'table',
            children: [
                {
                    title: t('control.3rd_party_pv_output'),
                    code: 'pv_inverter_nameplate_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => defaultValidate().integer().min(1).max(19200),
                },
            ],
        },
        {
            groupTitle: t('device.ess'),
            type: 'table',
            children: [
                {
                    title: t('control.energy_backup'),
                    code: 'battery_backup_soc',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                },
            ],
        },
        {
            groupTitle: t('device.grid'),
            type: 'table',
            children: [
                {
                    title: t('control.feed_in_limit_flag'),
                    code: 'pv_feed_in_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
                {
                    title: t('control.feedin_limit'),
                    code: 'pv_feed_in_limit_w',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => defaultValidate().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: t('device.measurement'),
            type: 'table',
            children: [
                {
                    title: t('device.consumption_ct'),
                    code: 'accb_consumption_ct_installed_point',
                    type: 'selector',
                    options: [
                        {
                            text: t('common.not_installed'),
                            value: CONSUMPTION_CT_TYPE.NOT_INSTALLED,
                        },
                        { text: t('common.grid_side'), value: CONSUMPTION_CT_TYPE.GRID_SIDE },
                        { text: t('common.load_side'), value: CONSUMPTION_CT_TYPE.LOAD_SIDE },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().nullable().notRequired(),
                },
                {
                    title: t('device.external_production_ct'),
                    code: 'accb_extended_production_ct_flag',
                    type: 'radio',
                    options: [
                        { text: t('common.installed'), value: 1 },
                        { text: t('common.not_installed'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => yup.number().nullable().notRequired(),
                },
                {
                    title: t('device.production_meter'),
                    code: 'accb_polarity_reverse_production_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.external_production_meter'),
                    code: 'accb_polarity_reverse_consumption_l1_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.consumption_meter_l1'),
                    code: 'accb_polarity_reverse_consumption_l2_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
                {
                    title: t('device.consumption_meter_l2'),
                    code: 'accb_polarity_reverse_ext_production_ct',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                },
            ],
        },
    ];
};
export const getEngineerSettingACSystemConfig = (t, options) => {
    return [
        {
            groupTitle: t('code.devicetypecd.inv'),
            type: 'table',
            children: [
                {
                    title: t('code.ermmode3cd.3'),
                    code: 'inverter_max_output_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(11400),
                },
                {
                    title: t('setting.acsys.inverter_grid_target_frequency'),
                    code: 'inverter_grid_target_frequency',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().min(50).max(60).unitNumber(0.01),
                },
                {
                    title: t('setting.acsys.inverter_external_generator_mode'),
                    code: 'inverter_external_generator_mode',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.inverter_permit_service_mode_select'),
                    code: 'inverter_permit_service_mode_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.inverter_enter_service_randomized_delay'),
                    code: 'inverter_enter_service_randomized_delay',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.inverter_import_power_limit'),
                    code: 'inverter_import_power_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate()
                        .integer()
                        .test('min', (value, context) => {
                        if (isNil(value) || isNil(context.parent.inverter_max_output_power)) {
                            return false;
                        }
                        return value >= -context.parent.inverter_max_output_power;
                    })
                        .max(0),
                },
                {
                    title: t('setting.acsys.inverter_export_power_limit'),
                    code: 'inverter_export_power_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(yup.ref('inverter_max_output_power')),
                },
                {
                    title: t('setting.acsys.battery_charge_limit'),
                    code: 'battery_charge_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(-32767).max(0),
                },
                {
                    title: t('setting.acsys.battery_discharge_limit'),
                    code: 'battery_discharge_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(32767),
                },
            ],
        },
        {
            groupTitle: t('device.battery_user_soc'),
            type: 'table',
            children: [
                {
                    title: t('common.min'),
                    code: 'battery_user_soc_min',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(yup.ref('battery_user_soc_max')),
                },
                {
                    title: t('common.max'),
                    code: 'battery_user_soc_max',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(yup.ref('battery_user_soc_min')).max(100),
                },
            ],
        },
        {
            groupTitle: t('common.hysteresis'),
            type: 'table',
            children: [
                {
                    title: t('common.low'),
                    code: 'battery_hysteresis_low',
                    type: 'selector',
                    options: [
                        { text: '10%', value: 10 },
                        { text: '15%', value: 15 },
                        { text: '20%', value: 20 },
                        { text: '25%', value: 25 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('common.high'),
                    code: 'battery_hysteresis_high',
                    type: 'selector',
                    options: [
                        { text: '90%', value: 90 },
                        { text: '85%', value: 85 },
                        { text: '80%', value: 80 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: t('setting.acsys.hub_external_device_control'),
            type: 'table',
            children: [
                {
                    title: t('setting.acsys.hub_external_inverter_flag'),
                    code: 'hub_external_inverter_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer(),
                },
                {
                    title: t('setting.acsys.hub_external_inverter_nameplate_power'),
                    code: 'hub_external_inverter_nameplate_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(19200).default(7600),
                },
                {
                    title: t('setting.acsys.hub_external_inverter2_flag'),
                    code: 'hub_external_inverter2_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer(),
                },
                {
                    title: t('setting.acsys.hub_external_inverter2_nameplate_power'),
                    code: 'hub_external_inverter2_nameplate_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(19200).default(7600),
                },
                {
                    title: t('setting.acsys.hub_generator_flag'),
                    code: 'hub_generator_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer(),
                },
                {
                    title: t('setting.acsys.hub_generator_remote_on'),
                    code: 'hub_generator_remote_on',
                    type: 'radio',
                    options: [
                        { text: t('setting.acsys.impossible'), value: 0 },
                        { text: t('setting.acsys.possible'), value: 1 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer(),
                },
                {
                    title: t('setting.acsys.hub_generator_nameplate_power'),
                    code: 'hub_generator_nameplate_power',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(0).max(15360).default(0),
                },
                {
                    title: t('setting.acsys.hub_msp_current_limit'),
                    code: 'hub_msp_current_limit',
                    type: 'number',
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer().min(-200).max(200),
                },
                {
                    title: t('setting.acsys.hub_external_ct_installed_point'),
                    code: 'hub_external_ct_installed_point',
                    type: 'selector',
                    options: options.extCtInstalledPointInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES],
                    schema: () => defaultValidate().integer(),
                },
            ],
        },
        {
            groupTitle: t('setting.acsys.ul3141'),
            type: 'table',
            children: [
                {
                    title: t('setting.acsys.ul3141_ess_mode'),
                    code: 'ul3141_ess_mode',
                    type: 'selector',
                    options: [
                        { text: t('setting.acsys.ul3141_unrestricted_mode'), value: 0 },
                        { text: t('setting.acsys.ul3141_import_only_mode'), value: 1 },
                        { text: t('setting.acsys.ul3141_export_only_mode'), value: 2 },
                        { text: t('setting.acsys.ul3141_no_exchange_mode'), value: 3 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
            ],
        },
        {
            groupTitle: t('setting.acsys.ul1741'),
            type: 'table',
            children: [
                {
                    title: t('setting.acsys.ul1741_flag'),
                    code: 'ul1741_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                },
            ],
        },
        {
            groupTitle: t('control.update_setting'),
            type: 'table',
            children: [
                {
                    title: t('control.auto_firm_update'),
                    code: 'auto_fw_update_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.SITE],
                    schema: () => defaultValidate().integer(),
                },
            ],
        },
        {
            groupTitle: t('device.pcs_external_ems'),
            type: 'table',
            children: [
                {
                    title: t('device.pcs_debug_mode'),
                    code: 'pcs_debug_mode',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate().integer(),
                },
            ],
        },
        {
            groupTitle: t('device.debug_info'),
            type: 'table',
            children: [
                {
                    title: t('control.process_mgr'),
                    code: 'log_level_process_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.system_log'),
                    code: 'log_level_system_log',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.fota'),
                    code: 'log_level_fota',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.pw_control'),
                    code: 'log_level_power_control',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.ess_mgr'),
                    code: 'log_level_ess_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.cloud_mgr'),
                    code: 'log_level_cloud_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.meter_mgr'),
                    code: 'log_level_meter_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.gw_mgr'),
                    code: 'log_level_gateway_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.data_mgr'),
                    code: 'log_level_data_accumulator',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.db_mgr'),
                    code: 'log_level_db_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('control.web_engine'),
                    code: 'log_level_web_engine',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_mlpe_mgr'),
                    code: 'log_level_mlpe_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_sys_mgr'),
                    code: 'log_level_sys_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_power_scheduler'),
                    code: 'log_level_power_scheduler',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_energy_link'),
                    code: 'log_level_energy_link',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_diag_monitor'),
                    code: 'log_level_diag_monitor',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_csip_mgr'),
                    code: 'log_level_csip_mgr',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_energy_optimizer'),
                    code: 'log_level_energy_optimizer',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.log_level_ipc_bridge'),
                    code: 'log_level_ipc_bridge',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.energy_monitor'),
                    code: 'log_level_energy_monitor',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.energy_scheduler'),
                    code: 'log_level_energy_scheduler',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.debug_mode_mqtt'),
                    code: 'debug_mode_mqtt',
                    type: 'selector',
                    options: options.debugInfo,
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
                {
                    title: t('setting.acsys.debug_mode_modbus'),
                    code: 'debug_mode_modbus',
                    type: 'toggle',
                    options: [
                        { text: t('common.on'), value: 1 },
                        { text: t('common.off'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL, AC_SYSTEM_FIELD_TYPE_CODE.ACCB],
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: t('control.site_configuration'),
            type: 'table',
            children: [
                {
                    title: t('setting.acsys.site_configuration_disable_mode'),
                    code: 'site_configuration_disable_mode',
                    type: 'toggle',
                    options: [
                        { text: t('common.on'), value: 1 },
                        { text: t('common.off'), value: 0 },
                    ],
                    target: [AC_SYSTEM_FIELD_TYPE_CODE.ACES_INDIVIDUAL],
                    schema: () => defaultValidate(),
                },
            ],
        },
    ];
};
// 아래와 같이 초기에 min/max/unit을 프로토콜 문서를 보고 admin 기준으로 설정해주는 이유는 내부적으로 SQA를 위해서
export const getAdvancedSettingACCBConfig = (t) => {
    return [
        {
            groupTitle: '[AC Combiner] Inverter Controls Basic Settings',
            children: [
                {
                    title: 'Vref',
                    name: 'inverter_control_grid_target_voltage_accb',
                    code: 'inverter_control_grid_target_voltage',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264.0).unitNumber(0.1),
                },
                {
                    title: 'Inverter Max Apparent Power',
                    name: 'inverter_control_pcs_max_apparent_power_limit_accb',
                    code: 'inverter_control_pcs_max_apparent_power_limit',
                    type: 'number',
                    min: '0',
                    max: '15356',
                    unit: 'VA',
                    schema: () => defaultValidate().integer().min(0).max(15356),
                },
                {
                    title: 'Active Power Setting',
                    name: 'inverter_control_active_power_setting_accb',
                    code: 'inverter_control_active_power_setting',
                    type: 'number',
                    min: '0',
                    max: '15356',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(15356),
                },
                {
                    title: 'VarMax',
                    name: 'inverter_control_pcs_var_max_q1_accb',
                    code: 'inverter_control_pcs_var_max_q1',
                    type: 'number',
                    min: '0',
                    max: '15356',
                    unit: 'VAr',
                    schema: () => defaultValidate().integer().min(0).max(15356),
                },
                {
                    title: 'WOvrExtPF',
                    name: 'inverter_control_pf_min_q1_accb',
                    code: 'inverter_control_pf_min_q1',
                    type: 'number',
                    min: '0.85',
                    max: '1.00',
                    schema: () => defaultValidate().min(0.85).max(1).unitNumber(0.01),
                },
                {
                    title: 'WUndExtPF',
                    name: 'inverter_control_pf_min_q2_accb',
                    code: 'inverter_control_pf_min_q2',
                    type: 'number',
                    min: '-1.00',
                    max: '-0.85',
                    schema: () => defaultValidate().min(-1).max(-0.85).unitNumber(0.01),
                },
                {
                    title: 'Active Power (Over-Excited) Setting',
                    name: 'inverter_control_active_power_over_excited_setting_accb',
                    code: 'inverter_control_active_power_over_excited_setting',
                    type: 'number',
                    min: '0',
                    max: '15356',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(15356),
                },
                {
                    title: 'Active Power (Under-Excited) Setting',
                    name: 'inverter_control_active_power_under_excited_setting_accb',
                    code: 'inverter_control_active_power_under_excited_setting',
                    type: 'number',
                    min: '0',
                    max: '15356',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(15356),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Over Voltage',
            children: [
                {
                    title: 'HVRT',
                    name: 'frt_hvrt_accb',
                    code: 'frt_hvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Over Voltage Detection Level1',
                    name: 'grid_voltage_over_voltage_detection_level1_accb',
                    code: 'grid_voltage_over_voltage_detection_level1',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time1',
                    name: 'grid_voltage_over_voltage_detection_time1_accb',
                    code: 'grid_voltage_over_voltage_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '51000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(51000),
                },
                {
                    title: 'Over Voltage Detection Level2',
                    name: 'grid_voltage_over_voltage_detection_level2_accb',
                    code: 'grid_voltage_over_voltage_detection_level2',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time2',
                    name: 'grid_voltage_over_voltage_detection_time2_accb',
                    code: 'grid_voltage_over_voltage_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '51000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(51000),
                },
                {
                    title: 'Momentary Cessation Detection Level1',
                    name: 'micro_inverter_der_trip_hv_crv_mom_cess_pt_accb',
                    code: 'micro_inverter_der_trip_hv_crv_mom_cess_pt',
                    type: 'number',
                    min: '264.0',
                    max: '300.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(300).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Release Level',
                    name: 'grid_voltage_over_voltage_release_level_accb',
                    code: 'grid_voltage_over_voltage_release_level',
                    type: 'number',
                    min: '249.6',
                    max: '276.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(249.6).max(276).unitNumber(0.1, 249.6),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Under Voltage',
            children: [
                {
                    title: 'LVRT',
                    name: 'frt_lvrt_accb',
                    code: 'frt_lvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Under Voltage Detection Level1',
                    name: 'grid_voltage_under_voltage_detection_level1_accb',
                    code: 'grid_voltage_under_voltage_detection_level1',
                    type: 'number',
                    min: '0.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time1',
                    name: 'grid_voltage_under_voltage_detection_time1_accb',
                    code: 'grid_voltage_under_voltage_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '51000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(51000),
                },
                {
                    title: 'Under Voltage Detection Level2',
                    name: 'grid_voltage_under_voltage_detection_level2_accb',
                    code: 'grid_voltage_under_voltage_detection_level2',
                    type: 'number',
                    min: '0.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time2',
                    name: 'grid_voltage_under_voltage_detection_time2_accb',
                    code: 'grid_voltage_under_voltage_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '51000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(51000),
                },
                {
                    title: 'Under Voltage Detection Level3',
                    name: 'grid_voltage_under_voltage_detection_level3_accb',
                    code: 'grid_voltage_under_voltage_detection_level3',
                    type: 'number',
                    min: '0.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time3',
                    name: 'grid_voltage_under_voltage_detection_time3_accb',
                    code: 'grid_voltage_under_voltage_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '51000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(51000),
                },
                {
                    title: 'Momentary Cessation Detection Level1',
                    name: 'micro_inverter_der_trip_lv_crv_mom_cess_pt_accb',
                    code: 'micro_inverter_der_trip_lv_crv_mom_cess_pt',
                    type: 'number',
                    min: '0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Release Level',
                    name: 'grid_voltage_under_voltage_release_level_accb',
                    code: 'grid_voltage_under_voltage_release_level',
                    type: 'number',
                    min: '211.2',
                    max: '228',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(228).unitNumber(0.01, 211.2),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Over Frequency',
            children: [
                {
                    title: 'HFRT',
                    name: 'frt_hfrt_accb',
                    code: 'frt_hfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Over Frequency Detection Level1',
                    name: 'grid_frequency_over_frequency_detection_level1_accb',
                    code: 'grid_frequency_over_frequency_detection_level1',
                    type: 'number',
                    min: '60.100',
                    max: '68.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.1).max(68).unitNumber(0.001, 60.1),
                },
                {
                    title: 'Over Frequency Detection Time1',
                    name: 'grid_frequency_over_frequency_detection_time1_accb',
                    code: 'grid_frequency_over_frequency_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000000),
                },
                {
                    title: 'Over Frequency Detection Level2',
                    name: 'grid_frequency_over_frequency_detection_level2_accb',
                    code: 'grid_frequency_over_frequency_detection_level2',
                    type: 'number',
                    min: '60.100',
                    max: '68.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.1).max(68).unitNumber(0.001, 60.1),
                },
                {
                    title: 'Over Frequency Detection Time2',
                    name: 'grid_frequency_over_frequency_detection_time2_accb',
                    code: 'grid_frequency_over_frequency_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000000),
                },
                {
                    title: 'Over Frequency Release Level',
                    name: 'grid_frequency_over_frequency_release_level_accb',
                    code: 'grid_frequency_over_frequency_release_level',
                    type: 'number',
                    min: '60.100',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.1).max(65).unitNumber(0.001, 60.1),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Under Frequency',
            children: [
                {
                    title: 'LFRT',
                    name: 'frt_lfrt_accb',
                    code: 'frt_lfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Under Frequency Detection Level1',
                    name: 'grid_frequency_under_frequency_detection_level1_accb',
                    code: 'grid_frequency_under_frequency_detection_level1',
                    type: 'number',
                    min: '50.000',
                    max: '59.900',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(59.9).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time1',
                    name: 'grid_frequency_under_frequency_detection_time1_accb',
                    code: 'grid_frequency_under_frequency_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000000),
                },
                {
                    title: 'Under Frequency Detection Level2',
                    name: 'grid_frequency_under_frequency_detection_level2_accb',
                    code: 'grid_frequency_under_frequency_detection_level2',
                    type: 'number',
                    min: '50.000',
                    max: '59.900',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(59.9).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time2',
                    name: 'grid_frequency_under_frequency_detection_time2_accb',
                    code: 'grid_frequency_under_frequency_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000000),
                },
                {
                    title: 'Under Frequency Release Level',
                    name: 'grid_frequency_under_frequency_release_level_accb',
                    code: 'grid_frequency_under_frequency_release_level',
                    type: 'number',
                    min: '55.000',
                    max: '59.900',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(55).max(59.9).unitNumber(0.001, 55),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Active Power SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    name: 'active_power_flag_accb',
                    code: 'active_power_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Value',
                    name: 'active_power_set_point_value_accb',
                    code: 'active_power_set_point_value',
                    type: 'number',
                    min: '5.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(100).unitNumber(0.1, 5),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Active Power Frequency P(freq)',
            children: [
                {
                    title: 'Frequency',
                    name: 'active_power_frequency_flag_accb',
                    code: 'active_power_frequency_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Frequency Droop Kof',
                    name: 'active_power_frequency_droop_kof_accb',
                    code: 'active_power_frequency_droop_kof',
                    type: 'number',
                    min: '0.017',
                    max: '0.167',
                    schema: () => defaultValidate().min(0.017).max(0.167).unitNumber(0.001, 0.017),
                },
                {
                    title: 'Frequency Droop Kuf',
                    name: 'active_power_frequency_droop_kuf_accb',
                    code: 'active_power_frequency_droop_kuf',
                    type: 'number',
                    min: '0.017',
                    max: '0.167',
                    schema: () => defaultValidate().min(0.017).max(0.167).unitNumber(0.001, 0.017),
                },
                {
                    title: 'X3: Frequency(fLLCO)',
                    name: 'active_power_frequency_x3_accb',
                    code: 'active_power_frequency_x3',
                    type: 'number',
                    min: '57.000',
                    max: '59.983',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(57).max(59.983).unitNumber(0.001, 57.0),
                },
                {
                    title: 'X4: Frequency(fULCO)',
                    name: 'active_power_frequency_x4_accb',
                    code: 'active_power_frequency_x4',
                    type: 'number',
                    min: '60.017',
                    max: '63.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.017).max(63).unitNumber(0.001, 60.017),
                },
                {
                    title: 'Frequency Droop Pmax',
                    name: 'micro_inverter_der_freq_droop_freq_watt_pmax_accb',
                    code: 'micro_inverter_der_freq_droop_freq_watt_pmax',
                    type: 'number',
                    min: '5.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(100).unitNumber(0.1, 5),
                },
                {
                    title: 'Frequency Droop Pmin',
                    name: 'micro_inverter_der_freq_droop_ctl_pmin_accb',
                    code: 'micro_inverter_der_freq_droop_ctl_pmin',
                    type: 'number',
                    min: '5.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(100).unitNumber(0.1, 5),
                },
                {
                    title: 'P(freq) Response Time',
                    name: 'active_power_frequency_response_time_accb',
                    code: 'active_power_frequency_response_time',
                    type: 'number',
                    min: '200',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(200).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Active Power Voltage P(V)',
            children: [
                {
                    title: 'Voltage',
                    name: 'active_power_voltage_flag_accb',
                    code: 'active_power_voltage_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Voltage X3(VW1)',
                    name: 'active_power_voltage_x3_accb',
                    code: 'active_power_voltage_x3',
                    type: 'number',
                    min: '252.0',
                    max: '261.6',
                    unit: 'V',
                    schema: () => defaultValidate().min(252).max(261.6).unitNumber(0.1, 252),
                },
                {
                    title: 'Voltage Y3',
                    name: 'active_power_voltage_y3_accb',
                    code: 'active_power_voltage_y3',
                    type: 'number',
                    min: '5.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(100).unitNumber(0.1, 5),
                },
                {
                    title: 'Voltage X4(VW2)',
                    name: 'active_power_voltage_x4_accb',
                    code: 'active_power_voltage_x4',
                    type: 'number',
                    min: '254.4',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(254.4).max(264).unitNumber(0.1, 254.4),
                },
                {
                    title: 'Voltage Y4',
                    name: 'active_power_voltage_y4_accb',
                    code: 'active_power_voltage_y4',
                    type: 'number',
                    min: '5.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(100).unitNumber(0.1, 5),
                },
                {
                    title: 'P(V) Response Time',
                    name: 'active_power_voltage_response_time_accb',
                    code: 'active_power_voltage_response_time',
                    type: 'number',
                    min: '500',
                    max: '90000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(500).max(90000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Power Factor SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    name: 'reactive_power_cospi_set_point_flag_accb',
                    code: 'reactive_power_cospi_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Excited',
                    name: 'reactive_power_cospi_set_point_excited_select_accb',
                    code: 'reactive_power_cospi_set_point_excited_select',
                    type: 'radio',
                    options: [
                        { text: 'Under', value: 1 },
                        { text: 'Over', value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Value',
                    name: 'reactive_power_cospi_set_point_value_accb',
                    code: 'reactive_power_cospi_set_point_value',
                    type: 'number',
                    min: '0.85',
                    max: '1.00',
                    schema: () => defaultValidate().min(0.85).max(1).unitNumber(0.01, 0.85),
                },
                {
                    title: 'Cospi(Setpoint) Response Time',
                    name: 'reactive_power_cospi_set_point_response_time_accb',
                    code: 'reactive_power_cospi_set_point_response_time',
                    type: 'number',
                    min: '1000',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(1000).max(10000),
                },
                {
                    title: 'Cospi(Setpoint) Apparent Power Priority',
                    name: 'reactive_power_cospi_set_point_apparent_power_priority_accb',
                    code: 'reactive_power_cospi_set_point_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        // { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Reactive Power Q(P)',
            children: [
                {
                    title: 'Q(P)',
                    name: 'reactive_power_q_p_flag_accb',
                    code: 'reactive_power_q_p_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(P) Reactive Power Mode',
                    name: 'reactive_power_q_p_reactive_power_mode_accb',
                    code: 'reactive_power_q_p_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(P) Apparent Power Priority',
                    name: 'reactive_power_q_p_apparent_power_priority_accb',
                    code: 'reactive_power_q_p_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        // { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'X1(Gen) : Power(P1)',
                    name: 'reactive_power_q_p_gen_x1_accb',
                    code: 'reactive_power_q_p_gen_x1',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    schema: () => defaultValidate().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y1(Gen) : Reactive Power(Q1)',
                    name: 'reactive_power_q_p_gen_y1_accb',
                    code: 'reactive_power_q_p_gen_y1',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X2(Gen) : Power(P2)',
                    name: 'reactive_power_q_p_gen_x2_accb',
                    code: 'reactive_power_q_p_gen_x2',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    schema: () => defaultValidate().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y2(Gen) : Reactive Power(Q2)',
                    name: 'reactive_power_q_p_gen_y2_accb',
                    code: 'reactive_power_q_p_gen_y2',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X3(Gen) : Power(P3)',
                    name: 'reactive_power_q_p_gen_x3_accb',
                    code: 'reactive_power_q_p_gen_x3',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    schema: () => defaultValidate().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y3(Gen) : Reactive Power(Q3)',
                    name: 'reactive_power_q_p_gen_y3_accb',
                    code: 'reactive_power_q_p_gen_y3',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(P) Response Time',
                    name: 'reactive_power_q_p_response_time_accb',
                    code: 'reactive_power_q_p_response_time',
                    type: 'number',
                    min: '1000',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(1000).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Reactive Power Q SetPoint',
            children: [
                {
                    title: 'Q(SetPoint)',
                    name: 'reactive_power_q_set_point_flag_accb',
                    code: 'reactive_power_q_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(Setpoint) Reactive Power Mode',
                    name: 'reactive_power_q_set_point_reactive_power_mode_accb',
                    code: 'reactive_power_q_set_point_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(Setpoint) Apparent Power Priority',
                    name: 'reactive_power_q_set_point_apparent_power_priority_accb',
                    code: 'reactive_power_q_set_point_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        // { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(SetPoint) Value(Q/S):',
                    name: 'reactive_power_q_set_point_value_accb',
                    code: 'reactive_power_q_set_point_value',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(Setpoint) Response Time',
                    name: 'reactive_power_q_set_point_response_time_accb',
                    code: 'reactive_power_q_set_point_response_time',
                    type: 'number',
                    min: '1000',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(1000).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Reactive Power Q(U)',
            children: [
                {
                    title: 'Q(U)',
                    name: 'reactive_power_q_u_flag_accb',
                    code: 'reactive_power_q_u_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Reactive Power Mode',
                    name: 'reactive_power_q_u_reactive_power_mode_accb',
                    code: 'reactive_power_q_u_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Apparent Power Priority',
                    name: 'reactive_power_q_u_apparent_power_priority_accb',
                    code: 'reactive_power_q_u_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        // { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Fixed Vref Select',
                    name: 'reactive_power_q_u_fixed_vref_select_accb',
                    code: 'reactive_power_q_u_fixed_vref_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Vref',
                    name: 'reactive_power_q_u_vref_accb',
                    code: 'reactive_power_q_u_vref',
                    type: 'number',
                    min: '228.0',
                    max: '252.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(228).max(252).unitNumber(0.1, 228),
                },
                {
                    title: 'Auto Vref Avg Time',
                    name: 'reactive_power_auto_vref_avg_time_accb',
                    code: 'reactive_power_auto_vref_avg_time',
                    type: 'number',
                    min: '300.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => defaultValidate().min(300).max(5000).unitNumber(0.1, 300),
                },
                {
                    title: 'X1: Voltage',
                    name: 'reactive_power_q_u_x1_accb',
                    code: 'reactive_power_q_u_x1',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y1: Reactive',
                    name: 'reactive_power_q_u_y1_accb',
                    code: 'reactive_power_q_u_y1',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X2: Voltage',
                    name: 'reactive_power_q_u_x2_accb',
                    code: 'reactive_power_q_u_x2',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y2: Reactive',
                    name: 'reactive_power_q_u_y2_accb',
                    code: 'reactive_power_q_u_y2',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X3: Voltage',
                    name: 'reactive_power_q_u_x3_accb',
                    code: 'reactive_power_q_u_x3',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y3: Reactive',
                    name: 'reactive_power_q_u_y3_accb',
                    code: 'reactive_power_q_u_y3',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X4: Voltage',
                    name: 'reactive_power_q_u_x4_accb',
                    code: 'reactive_power_q_u_x4',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y4: Reactive',
                    name: 'reactive_power_q_u_y4_accb',
                    code: 'reactive_power_q_u_y4',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(U) Response Time',
                    name: 'reactive_power_q_u_response_time_accb',
                    code: 'reactive_power_q_u_response_time',
                    type: 'number',
                    min: '1000',
                    max: '90000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(1000).max(90000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Anti_Islanding Control',
            children: [
                {
                    title: 'Active Method Detection',
                    name: 'anti_islanding_active_method_detection_accb',
                    code: 'anti_islanding_active_method_detection',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Gradient Control',
            children: [
                {
                    title: 'Enable',
                    name: 'gradient_flag_accb',
                    code: 'gradient_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                // {
                //   title: 'Energy Source Change Enable',
                //   code: 'gradient_energy_source_change',
                //   type: 'toggle',
                //   schema: () => defaultValidate(),
                // },
                {
                    title: 'Unit Reference Select ',
                    name: 'gradient_unit_reference_select_accb',
                    code: 'gradient_unit_reference_select',
                    type: 'radio',
                    options: [
                        // { text: 'Irated', value: 0 },
                        { text: 'Prated', value: 1 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Active Power Gradient',
                    name: 'gradient_active_power_gradient_accb',
                    code: 'gradient_active_power_gradient',
                    type: 'number',
                    min: '0.10',
                    max: '100.00',
                    unit: '%W/s',
                    schema: () => defaultValidate().min(0.1).max(100).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Re-connection Time',
            children: [
                {
                    title: 'Time',
                    name: 'inverter_reconnection_time_accb',
                    code: 'inverter_reconnection_time',
                    type: 'number',
                    min: '0',
                    max: '600',
                    unit: 'sec',
                    schema: () => defaultValidate().integer().min(0).max(600),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Randomized delay Time',
            children: [
                {
                    title: 'Randomized delay Time',
                    name: 'inverter_randomized_delay_time_accb',
                    code: 'inverter_randomized_delay_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'sec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Normal Ramp-rate',
            children: [
                {
                    title: 'Enable',
                    name: 'micro_inverter_ena_rmp_accb',
                    code: 'micro_inverter_ena_rmp',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'WRmp',
                    name: 'micro_inverter_w_rmp_accb',
                    code: 'micro_inverter_w_rmp',
                    type: 'number',
                    min: '0.10',
                    max: '100.00',
                    unit: '%W/s',
                    schema: () => defaultValidate().min(0.1).max(100).unitNumber(0.01, 0.1),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Protection Function',
            children: [
                {
                    title: 'Transient Over Voltage Detection',
                    name: 'micro_inverter_ena_tov_accb',
                    code: 'micro_inverter_ena_tov',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Non-insulation Detection',
                    name: 'micro_inverter_ena_gfdi_accb',
                    code: 'micro_inverter_ena_gfdi',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: '[AC Combiner] Compensation Control',
            children: [
                {
                    title: 'Enable',
                    name: 'micro_inverter_ena_flag_nr_config_accb',
                    code: 'micro_inverter_ena_flag_nr_config',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Vpv Min',
                    name: 'micro_inverter_pv_settings_vpv_min_accb',
                    code: 'micro_inverter_pv_settings_vpv_min',
                    type: 'number',
                    min: '0',
                    max: '60',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(60),
                },
                {
                    title: 'Vpv Max',
                    name: 'micro_inverter_pv_settings_vpv_max_accb',
                    code: 'micro_inverter_pv_settings_vpv_max',
                    type: 'number',
                    min: '0',
                    max: '60',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(60),
                },
                {
                    title: 'Ipv Max',
                    name: 'micro_inverter_pv_settings_ipv_max_accb',
                    code: 'micro_inverter_pv_settings_ipv_max',
                    type: 'number',
                    min: '0',
                    max: '20',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(20),
                },
                {
                    title: 'Temperature Max',
                    name: 'micro_inverter_pv_settings_temp_max_accb',
                    code: 'micro_inverter_pv_settings_temp_max',
                    type: 'number',
                    min: '0',
                    max: '150',
                    unit: UNIT.CELSIUS,
                    schema: () => defaultValidate().min(0).max(150),
                },
                {
                    title: 'Temperature Min',
                    name: 'micro_inverter_pv_settings_temp_min_accb',
                    code: 'micro_inverter_pv_settings_temp_min',
                    type: 'number',
                    min: '-45',
                    max: '0',
                    unit: UNIT.CELSIUS,
                    schema: () => defaultValidate().min(-45).max(0),
                },
                {
                    title: 'Power Calibration',
                    name: 'micro_inverter_pv_settings_pow_calib_accb',
                    code: 'micro_inverter_pv_settings_pow_calib',
                    type: 'number',
                    min: '-50',
                    max: '50',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(-50).max(50),
                },
            ],
        },
    ];
};
export const getAdvancedSettingACESConfig = (t) => {
    return [
        {
            groupTitle: '[AC Coupled ESS + HUB] Tau Coefficient',
            children: [
                {
                    title: 'Tau',
                    name: 'inverter_tau_coefficient_aces',
                    code: 'inverter_tau_coefficient',
                    type: 'number',
                    min: '0.0',
                    max: '6.0',
                    schema: () => defaultValidate().min(0).max(6.0).unitNumber(0.1),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Inverter Controls Basic Settings',
            children: [
                {
                    title: 'Vref',
                    name: 'inverter_control_grid_target_voltage_aces',
                    code: 'inverter_control_grid_target_voltage',
                    type: 'number',
                    min: '211.2',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(264.0).unitNumber(0.1),
                },
                {
                    title: 'Vref Offset',
                    name: 'inverter_control_grid_target_voltage_offset_aces',
                    code: 'inverter_control_grid_target_voltage_offset',
                    type: 'number',
                    min: '-300.0',
                    max: '300.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(-300).max(300).unitNumber(0.1),
                },
                {
                    title: 'Inverter Max Apparent Power',
                    name: 'inverter_control_pcs_max_apparent_power_limit_aces',
                    code: 'inverter_control_pcs_max_apparent_power_limit',
                    type: 'number',
                    min: '0',
                    max: '11400',
                    unit: 'VA',
                    schema: () => defaultValidate().integer().min(0).max(11400),
                },
                {
                    title: 'Export Pmin',
                    name: 'inverter_control_export_pmin_aces',
                    code: 'inverter_control_export_pmin',
                    type: 'number',
                    min: '0.0',
                    max: '20.0',
                    unit: '%',
                    schema: () => defaultValidate().min(0).max(20).unitNumber(0.1),
                },
                {
                    title: 'Import Pmin',
                    name: 'inverter_control_import_pmin_aces',
                    code: 'inverter_control_import_pmin',
                    type: 'number',
                    min: '-100.0',
                    max: '5',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(5).unitNumber(0.1),
                },
                {
                    title: 'VArMaxQ1',
                    name: 'inverter_control_pcs_var_max_q1_aces',
                    code: 'inverter_control_pcs_var_max_q1',
                    type: 'number',
                    min: '0',
                    max: '4560',
                    unit: 'VAr',
                    schema: () => defaultValidate().integer().min(0).max(4560),
                },
                {
                    title: 'VArMaxQ2',
                    name: 'inverter_control_pcs_var_max_q2_aces',
                    code: 'inverter_control_pcs_var_max_q2',
                    type: 'number',
                    min: '0',
                    max: '4560',
                    unit: 'VAr',
                    schema: () => defaultValidate().integer().min(0).max(4560),
                },
                {
                    title: 'VArMaxQ3',
                    name: 'inverter_control_pcs_var_max_q3_aces',
                    code: 'inverter_control_pcs_var_max_q3',
                    type: 'number',
                    min: '-4560',
                    max: '0',
                    unit: 'VAr',
                    schema: () => defaultValidate().integer().min(-4560).max(0),
                },
                {
                    title: 'VArMaxQ4',
                    name: 'inverter_control_pcs_var_max_q4_aces',
                    code: 'inverter_control_pcs_var_max_q4',
                    type: 'number',
                    min: '-4560',
                    max: '0',
                    unit: 'VAr',
                    schema: () => defaultValidate().integer().min(-4560).max(0),
                },
                {
                    title: 'PFMinQ1',
                    name: 'inverter_control_pf_min_q1_aces',
                    code: 'inverter_control_pf_min_q1',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ2',
                    name: 'inverter_control_pf_min_q2_aces',
                    code: 'inverter_control_pf_min_q2',
                    type: 'number',
                    min: '-1.00',
                    max: '0.00',
                    schema: () => defaultValidate().min(-1).max(0).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ3',
                    name: 'inverter_control_pf_min_q3_aces',
                    code: 'inverter_control_pf_min_q3',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ4',
                    name: 'inverter_control_pf_min_q4_aces',
                    code: 'inverter_control_pf_min_q4',
                    type: 'number',
                    min: '-1.00',
                    max: '0.00',
                    schema: () => defaultValidate().min(-1).max(0).unitNumber(0.01),
                },
                {
                    title: 'Charge Rate Setting',
                    name: 'inverter_control_charge_rate_setting_aces',
                    code: 'inverter_control_charge_rate_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
                {
                    title: 'Discharge Rate Setting',
                    name: 'inverter_control_discharge_rate_setting_aces',
                    code: 'inverter_control_discharge_rate_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
                {
                    title: 'Charge Rate VA Setting',
                    name: 'inverter_control_va_setting_aces',
                    code: 'inverter_control_va_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
                {
                    title: 'Active Power Setting',
                    name: 'inverter_control_active_power_setting_aces',
                    code: 'inverter_control_active_power_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
                {
                    title: 'Active Power (Over-Excited) Setting',
                    name: 'inverter_control_active_power_over_excited_setting_aces',
                    code: 'inverter_control_active_power_over_excited_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
                {
                    title: 'Active Power (Under-Excited) Setting',
                    name: 'inverter_control_active_power_under_excited_setting_aces',
                    code: 'inverter_control_active_power_under_excited_setting',
                    type: 'number',
                    min: '0',
                    max: '7600',
                    unit: 'W',
                    schema: () => defaultValidate().integer().min(0).max(7600),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Over Voltage',
            children: [
                {
                    title: 'HVRT',
                    name: 'frt_hvrt_aces',
                    code: 'frt_hvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'HVRT Start Level',
                    name: 'frt_start_level_hvrt_aces',
                    code: 'frt_start_level_hvrt',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Level1',
                    name: 'grid_voltage_over_voltage_detection_level1_aces',
                    code: 'grid_voltage_over_voltage_detection_level1',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time1',
                    name: 'grid_voltage_over_voltage_detection_time1_aces',
                    code: 'grid_voltage_over_voltage_detection_time1',
                    type: 'number',
                    min: '160',
                    max: '13000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(13000),
                },
                {
                    title: 'Over Voltage Detection Level2',
                    name: 'grid_voltage_over_voltage_detection_level2_aces',
                    code: 'grid_voltage_over_voltage_detection_level2',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time2',
                    name: 'grid_voltage_over_voltage_detection_time2_aces',
                    code: 'grid_voltage_over_voltage_detection_time2',
                    type: 'number',
                    min: '160',
                    max: '13000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(13000),
                },
                {
                    title: 'Over Voltage Detection Level3',
                    name: 'grid_voltage_over_voltage_detection_level3_aces',
                    code: 'grid_voltage_over_voltage_detection_level3',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time3',
                    name: 'grid_voltage_over_voltage_detection_time3_aces',
                    code: 'grid_voltage_over_voltage_detection_time3',
                    type: 'number',
                    min: '1000',
                    max: '13000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(1000).max(13000),
                },
                {
                    title: 'Over Voltage Detection Level4',
                    name: 'grid_voltage_over_voltage_detection_level4_aces',
                    code: 'grid_voltage_over_voltage_detection_level4',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time4',
                    name: 'grid_voltage_over_voltage_detection_time4_aces',
                    code: 'grid_voltage_over_voltage_detection_time4',
                    type: 'number',
                    min: '1000',
                    max: '13000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(1000).max(13000),
                },
                {
                    title: 'Over Voltage Detection Level5',
                    name: 'grid_voltage_over_voltage_detection_level5_aces',
                    code: 'grid_voltage_over_voltage_detection_level5',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
                {
                    title: 'Over Voltage Detection Time5',
                    name: 'grid_voltage_over_voltage_detection_time5_aces',
                    code: 'grid_voltage_over_voltage_detection_time5',
                    type: 'number',
                    min: '1000',
                    max: '13000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(1000).max(13000),
                },
                {
                    title: 'Over Voltage Release Level',
                    name: 'grid_voltage_over_voltage_release_level_aces',
                    code: 'grid_voltage_over_voltage_release_level',
                    type: 'number',
                    min: '252.0',
                    max: '254.4',
                    unit: 'V',
                    schema: () => defaultValidate().min(252.0).max(254.4).unitNumber(0.1, 252.0),
                },
                {
                    title: 'Release Time',
                    name: 'grid_voltage_over_voltage_release_time_aces',
                    code: 'grid_voltage_over_voltage_release_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] 10Min Voltage',
            children: [
                {
                    title: 'Detection Level',
                    name: 'grid_voltage_ten_min_voltage_detection_level_aces',
                    code: 'grid_voltage_ten_min_voltage_detection_level',
                    type: 'number',
                    min: '264.0',
                    max: '288.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(264).max(288).unitNumber(0.1, 264),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Under Voltage',
            children: [
                {
                    title: 'LVRT',
                    name: 'frt_lvrt_aces',
                    code: 'frt_lvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'LVRT Start Level',
                    name: 'frt_start_level_lvrt_aces',
                    code: 'frt_start_level_lvrt',
                    type: 'number',
                    min: '120.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(120).max(211.2).unitNumber(0.1, 120),
                },
                {
                    title: 'Under Voltage Detection Level1',
                    name: 'grid_voltage_under_voltage_detection_level1_aces',
                    code: 'grid_voltage_under_voltage_detection_level1',
                    type: 'number',
                    min: '0.0',
                    max: '120.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(120.0).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time1',
                    name: 'grid_voltage_under_voltage_detection_time1_aces',
                    code: 'grid_voltage_under_voltage_detection_time1',
                    type: 'number',
                    min: '160',
                    max: '21000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(21000),
                },
                {
                    title: 'Under Voltage Detection Level2',
                    name: 'grid_voltage_under_voltage_detection_level2_aces',
                    code: 'grid_voltage_under_voltage_detection_level2',
                    type: 'number',
                    min: '0.0',
                    max: '120.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(120.0).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time2',
                    name: 'grid_voltage_under_voltage_detection_time2_aces',
                    code: 'grid_voltage_under_voltage_detection_time2',
                    type: 'number',
                    min: '160',
                    max: '21000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(21000),
                },
                {
                    title: 'Under Voltage Detection Level3',
                    name: 'grid_voltage_under_voltage_detection_level3_aces',
                    code: 'grid_voltage_under_voltage_detection_level3',
                    type: 'number',
                    min: '0.0',
                    max: '120.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(120.0).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time3',
                    name: 'grid_voltage_under_voltage_detection_time3_aces',
                    code: 'grid_voltage_under_voltage_detection_time3',
                    type: 'number',
                    min: '2000',
                    max: '50000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(2000).max(50000),
                },
                {
                    title: 'Under Voltage Detection Level4',
                    name: 'grid_voltage_under_voltage_detection_level4_aces',
                    code: 'grid_voltage_under_voltage_detection_level4',
                    type: 'number',
                    min: '0.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time4',
                    name: 'grid_voltage_under_voltage_detection_time4_aces',
                    code: 'grid_voltage_under_voltage_detection_time4',
                    type: 'number',
                    min: '2000',
                    max: '50000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(2000).max(50000),
                },
                {
                    title: 'Under Voltage Detection Level5',
                    name: 'grid_voltage_under_voltage_detection_level5_aces',
                    code: 'grid_voltage_under_voltage_detection_level5',
                    type: 'number',
                    min: '0.0',
                    max: '211.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(0).max(211.2).unitNumber(0.1, 0),
                },
                {
                    title: 'Under Voltage Detection Time5',
                    name: 'grid_voltage_under_voltage_detection_time5_aces',
                    code: 'grid_voltage_under_voltage_detection_time5',
                    type: 'number',
                    min: '2000',
                    max: '50000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(2000).max(50000),
                },
                {
                    title: 'Under Voltage Release Level',
                    name: 'grid_voltage_under_voltage_release_level_aces',
                    code: 'grid_voltage_under_voltage_release_level',
                    type: 'number',
                    min: '211.2',
                    max: '228.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(211.2).max(228).unitNumber(0.01, 211.2),
                },
                {
                    title: 'Release Time',
                    name: 'grid_voltage_under_voltage_release_time_aces',
                    code: 'grid_voltage_under_voltage_release_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Over Frequency',
            children: [
                {
                    title: 'HFRT',
                    name: 'frt_hfrt_aces',
                    code: 'frt_hfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'HFRT Start Level',
                    name: 'frt_start_level_hfrt_aces',
                    code: 'frt_start_level_hfrt',
                    type: 'number',
                    min: '61.000',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.0).max(66).unitNumber(0.001, 61.0),
                },
                {
                    title: 'Over Frequency Detection Level1',
                    name: 'grid_frequency_over_frequency_detection_level1_aces',
                    code: 'grid_frequency_over_frequency_detection_level1',
                    type: 'number',
                    min: '61.800',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.8).max(66.0).unitNumber(0.001, 61.8),
                },
                {
                    title: 'Over Frequency Detection Time1',
                    name: 'grid_frequency_over_frequency_detection_time1_aces',
                    code: 'grid_frequency_over_frequency_detection_time1',
                    type: 'number',
                    min: '160',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(1000000),
                },
                {
                    title: 'Over Frequency Detection Level2',
                    name: 'grid_frequency_over_frequency_detection_level2_aces',
                    code: 'grid_frequency_over_frequency_detection_level2',
                    type: 'number',
                    min: '61.800',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.8).max(66).unitNumber(0.001, 61.8),
                },
                {
                    title: 'Over Frequency Detection Time2',
                    name: 'grid_frequency_over_frequency_detection_time2_aces',
                    code: 'grid_frequency_over_frequency_detection_time2',
                    type: 'number',
                    min: '160',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(1000000),
                },
                {
                    title: 'Over Frequency Detection Level3',
                    name: 'grid_frequency_over_frequency_detection_level3_aces',
                    code: 'grid_frequency_over_frequency_detection_level3',
                    type: 'number',
                    min: '61.800',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.8).max(66).unitNumber(0.001, 61.8),
                },
                {
                    title: 'Over Frequency Detection Time3',
                    name: 'grid_frequency_over_frequency_detection_time3_aces',
                    code: 'grid_frequency_over_frequency_detection_time3',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Over Frequency Detection Level4',
                    name: 'grid_frequency_over_frequency_detection_level4_aces',
                    code: 'grid_frequency_over_frequency_detection_level4',
                    type: 'number',
                    min: '61.000',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.0).max(66).unitNumber(0.001, 61.0),
                },
                {
                    title: 'Over Frequency Detection Time4',
                    name: 'grid_frequency_over_frequency_detection_time4_aces',
                    code: 'grid_frequency_over_frequency_detection_time4',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Over Frequency Detection Level5',
                    name: 'grid_frequency_over_frequency_detection_level5_aces',
                    code: 'grid_frequency_over_frequency_detection_level5',
                    type: 'number',
                    min: '61.000',
                    max: '66.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(61.0).max(66).unitNumber(0.001, 61.0),
                },
                {
                    title: 'Over Frequency Detection Time5',
                    name: 'grid_frequency_over_frequency_detection_time5_aces',
                    code: 'grid_frequency_over_frequency_detection_time5',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Over Frequency Release Level',
                    name: 'grid_frequency_over_frequency_release_level_aces',
                    code: 'grid_frequency_over_frequency_release_level',
                    type: 'number',
                    min: '60.000',
                    max: '61.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.0).max(61.0).unitNumber(0.001, 60.0),
                },
                {
                    title: 'Release Time',
                    name: 'grid_frequency_over_frequency_release_time_aces',
                    code: 'grid_frequency_over_frequency_release_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Under Frequency',
            children: [
                {
                    title: 'LFRT',
                    name: 'frt_lfrt_aces',
                    code: 'frt_lfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'LFRT Start Level',
                    name: 'frt_start_level_lfrt_aces',
                    code: 'frt_start_level_lfrt',
                    type: 'number',
                    min: '50.000',
                    max: '59.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(59).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Level1',
                    name: 'grid_frequency_under_frequency_detection_level1_aces',
                    code: 'grid_frequency_under_frequency_detection_level1',
                    type: 'number',
                    min: '50.000',
                    max: '57.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(57.0).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time1',
                    name: 'grid_frequency_under_frequency_detection_time1_aces',
                    code: 'grid_frequency_under_frequency_detection_time1',
                    type: 'number',
                    min: '160',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(1000000),
                },
                {
                    title: 'Under Frequency Detection Level2',
                    name: 'grid_frequency_under_frequency_detection_level2_aces',
                    code: 'grid_frequency_under_frequency_detection_level2',
                    type: 'number',
                    min: '50.000',
                    max: '57.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(57).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time2',
                    name: 'grid_frequency_under_frequency_detection_time2_aces',
                    code: 'grid_frequency_under_frequency_detection_time2',
                    type: 'number',
                    min: '160',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(160).max(1000000),
                },
                {
                    title: 'Under Frequency Detection Level3',
                    name: 'grid_frequency_under_frequency_detection_level3_aces',
                    code: 'grid_frequency_under_frequency_detection_level3',
                    type: 'number',
                    min: '50.000',
                    max: '57.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(57).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time3',
                    name: 'grid_frequency_under_frequency_detection_time3_aces',
                    code: 'grid_frequency_under_frequency_detection_time3',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Under Frequency Detection Level4',
                    name: 'grid_frequency_under_frequency_detection_level4_aces',
                    code: 'grid_frequency_under_frequency_detection_level4',
                    type: 'number',
                    min: '50.000',
                    max: '59.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(59).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time4',
                    name: 'grid_frequency_under_frequency_detection_time4_aces',
                    code: 'grid_frequency_under_frequency_detection_time4',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Under Frequency Detection Level5',
                    name: 'grid_frequency_under_frequency_detection_level5_aces',
                    code: 'grid_frequency_under_frequency_detection_level5',
                    type: 'number',
                    min: '50.000',
                    max: '59.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(50).max(59).unitNumber(0.001, 50),
                },
                {
                    title: 'Under Frequency Detection Time5',
                    name: 'grid_frequency_under_frequency_detection_time5_aces',
                    code: 'grid_frequency_under_frequency_detection_time5',
                    type: 'number',
                    min: '180000',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(180000).max(1000000),
                },
                {
                    title: 'Under Frequency Release Level',
                    name: 'grid_frequency_under_frequency_release_level_aces',
                    code: 'grid_frequency_under_frequency_release_level',
                    type: 'number',
                    min: '59.000',
                    max: '59.900',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(59).max(59.9).unitNumber(0.001, 59),
                },
                {
                    title: 'Release Time',
                    name: 'grid_frequency_under_frequency_release_time_aces',
                    code: 'grid_frequency_under_frequency_release_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'msec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Active Power SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    name: 'active_power_flag_aces',
                    code: 'active_power_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Value',
                    name: 'active_power_set_point_value_aces',
                    code: 'active_power_set_point_value',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(-100.0).max(100).unitNumber(0.1, -100.0),
                },
                {
                    title: 'Active Power SetPoint Gradient',
                    name: 'gradient_active_power_set_point_gradient_aces',
                    code: 'gradient_active_power_set_point_gradient',
                    type: 'number',
                    min: '0.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(0).max(100).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Active Power Frequency P(freq)',
            children: [
                {
                    title: 'Frequency',
                    name: 'active_power_frequency_flag_aces',
                    code: 'active_power_frequency_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'OF Pref Select',
                    name: 'active_power_frequency_of_pref_select_aces',
                    code: 'active_power_frequency_of_pref_select',
                    type: 'radio',
                    options: [
                        { text: 'Pmax', value: 0 },
                        { text: 'Pref', value: 1 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'UF Pref Select',
                    name: 'active_power_frequency_uf_pref_select_aces',
                    code: 'active_power_frequency_uf_pref_select',
                    type: 'radio',
                    options: [
                        { text: 'Pmax', value: 0 },
                        { text: 'Pref', value: 1 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Slope Select',
                    name: 'active_power_frequency_slope_select_aces',
                    code: 'active_power_frequency_slope_select',
                    type: 'radio',
                    options: [
                        { text: 'Curve', value: 0 },
                        { text: 'Slope', value: 1 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Hysteresis Select',
                    name: 'active_power_frequency_hysteresis_select_aces',
                    code: 'active_power_frequency_hysteresis_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Frequency Droop Kof',
                    name: 'active_power_frequency_droop_kof_aces',
                    code: 'active_power_frequency_droop_kof',
                    type: 'number',
                    min: '0.0200',
                    max: '0.0700',
                    schema: () => defaultValidate().min(0.02).max(0.07).unitNumber(0.001, 0.02),
                },
                {
                    title: 'Frequency Droop Kuf',
                    name: 'active_power_frequency_droop_kuf_aces',
                    code: 'active_power_frequency_droop_kuf',
                    type: 'number',
                    min: '0.0200',
                    max: '0.0700',
                    schema: () => defaultValidate().min(0.02).max(0.07).unitNumber(0.001, 0.02),
                },
                {
                    title: 'X3: Frequency(fLLCO)',
                    name: 'active_power_frequency_x3_aces',
                    code: 'active_power_frequency_x3',
                    type: 'number',
                    min: '59.000',
                    max: '59.983',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(59.0).max(59.983).unitNumber(0.001, 59.0),
                },
                {
                    title: 'X4: Frequency(fULCO)',
                    name: 'active_power_frequency_x4_aces',
                    code: 'active_power_frequency_x4',
                    type: 'number',
                    min: '60.017',
                    max: '61.000',
                    unit: 'Hz',
                    schema: () => defaultValidate().min(60.017).max(61).unitNumber(0.001, 60.017),
                },
                {
                    title: 'P(freq) Response Time',
                    name: 'active_power_frequency_response_time_aces',
                    code: 'active_power_frequency_response_time',
                    type: 'number',
                    min: '200',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(200).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Active Power Voltage P(V)',
            children: [
                {
                    title: 'Voltage',
                    name: 'active_power_voltage_flag_aces',
                    code: 'active_power_voltage_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Voltage X3(VW1)',
                    name: 'active_power_voltage_x3_aces',
                    code: 'active_power_voltage_x3',
                    type: 'number',
                    min: '252.0',
                    max: '261.6',
                    unit: 'V',
                    schema: () => defaultValidate().min(252).max(261.6).unitNumber(0.1, 252),
                },
                {
                    title: 'Voltage Y3',
                    name: 'active_power_voltage_y3_aces',
                    code: 'active_power_voltage_y3',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(-100.0).max(100).unitNumber(0.1, -100.0),
                },
                {
                    title: 'Voltage X4(VW2)',
                    name: 'active_power_voltage_x4_aces',
                    code: 'active_power_voltage_x4',
                    type: 'number',
                    min: '254.4',
                    max: '264.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(254.4).max(264).unitNumber(0.1, 254.4),
                },
                {
                    title: 'Voltage Y4',
                    name: 'active_power_voltage_y4_aces',
                    code: 'active_power_voltage_y4',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(-100.0).max(100).unitNumber(0.1, -100.0),
                },
                {
                    title: 'P(V) Response Time',
                    name: 'active_power_voltage_response_time_aces',
                    code: 'active_power_voltage_response_time',
                    type: 'number',
                    min: '500',
                    max: '60000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(500).max(60000),
                },
            ],
        },
        // @TODO 추가하기
        // {
        //   groupTitle: 'Logic Interface',
        //   children: [
        //     {
        //       title: 'Inverter logic interface',
        //       code: 'inverter_logic_interface',
        //       type: 'toggle',
        //       schema: () => defaultValidate(),
        //     },
        //   ],
        // },
        {
            groupTitle: '[AC Coupled ESS + HUB] Power Factor SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    name: 'reactive_power_cospi_set_point_flag_aces',
                    code: 'reactive_power_cospi_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Excited',
                    name: 'reactive_power_cospi_set_point_excited_select_aces',
                    code: 'reactive_power_cospi_set_point_excited_select',
                    type: 'radio',
                    options: [
                        { text: 'Under', value: 1 },
                        { text: 'Over', value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'SetPoint Value',
                    name: 'reactive_power_cospi_set_point_value_aces',
                    code: 'reactive_power_cospi_set_point_value',
                    type: 'number',
                    min: '0.80',
                    max: '1.00',
                    schema: () => defaultValidate().min(0.8).max(1).unitNumber(0.01, 0.8),
                },
                {
                    title: 'Cospi(Setpoint) Response Time',
                    name: 'reactive_power_cospi_set_point_response_time_aces',
                    code: 'reactive_power_cospi_set_point_response_time',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
                {
                    title: 'Cospi(Setpoint) Apparent Power Priority',
                    name: 'reactive_power_cospi_set_point_apparent_power_priority_aces',
                    code: 'reactive_power_cospi_set_point_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Reactive Power Q(P)',
            children: [
                {
                    title: 'Q(P)',
                    name: 'reactive_power_q_p_flag_aces',
                    code: 'reactive_power_q_p_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(P) Reactive Power Mode',
                    name: 'reactive_power_q_p_reactive_power_mode_aces',
                    code: 'reactive_power_q_p_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(P) Apparent Power Priority',
                    name: 'reactive_power_q_p_apparent_power_priority_aces',
                    code: 'reactive_power_q_p_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'X1(Gen) : Power(P1)',
                    name: 'reactive_power_q_p_gen_x1_aces',
                    code: 'reactive_power_q_p_gen_x1',
                    type: 'number',
                    min: '5.0',
                    max: '70.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(70).unitNumber(0.1),
                },
                {
                    title: 'Y1(Gen) : Reactive Power(Q1)',
                    name: 'reactive_power_q_p_gen_y1_aces',
                    code: 'reactive_power_q_p_gen_y1',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X2(Gen) : Power(P2)',
                    name: 'reactive_power_q_p_gen_x2_aces',
                    code: 'reactive_power_q_p_gen_x2',
                    type: 'number',
                    min: '40.0',
                    max: '80.0',
                    unit: '%',
                    schema: () => defaultValidate().min(40).max(80).unitNumber(0.1),
                },
                {
                    title: 'Y2(Gen) : Reactive Power(Q2)',
                    name: 'reactive_power_q_p_gen_y2_aces',
                    code: 'reactive_power_q_p_gen_y2',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X3(Gen) : Power(P3)',
                    name: 'reactive_power_q_p_gen_x3_aces',
                    code: 'reactive_power_q_p_gen_x3',
                    type: 'number',
                    min: '50.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(50).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y3(Gen) : Reactive Power(Q3)',
                    name: 'reactive_power_q_p_gen_y3_aces',
                    code: 'reactive_power_q_p_gen_y3',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: "X1(Load) : Power(P'1)",
                    name: 'reactive_power_q_p_load_x1_aces',
                    code: 'reactive_power_q_p_load_x1',
                    type: 'number',
                    min: '5.0',
                    max: '70.0',
                    unit: '%',
                    schema: () => defaultValidate().min(5).max(70).unitNumber(0.1),
                },
                {
                    title: "Y1(Load) : Reactive Power(Q'1)",
                    name: 'reactive_power_q_p_load_y1_aces',
                    code: 'reactive_power_q_p_load_y1',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: "X2(Load) : Power(P'2)",
                    name: 'reactive_power_q_p_load_x2_aces',
                    code: 'reactive_power_q_p_load_x2',
                    type: 'number',
                    min: '40.0',
                    max: '80.0',
                    unit: '%',
                    schema: () => defaultValidate().min(40).max(80).unitNumber(0.1),
                },
                {
                    title: "Y2(Load) : Reactive Power(Q'2)",
                    name: 'reactive_power_q_p_load_y2_aces',
                    code: 'reactive_power_q_p_load_y2',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: "X3(Load) : Power(P'3)",
                    name: 'reactive_power_q_p_load_x3_aces',
                    code: 'reactive_power_q_p_load_x3',
                    type: 'number',
                    min: '50.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => defaultValidate().min(50).max(100).unitNumber(0.1),
                },
                {
                    title: "Y3(Load) : Reactive Power(Q'3)",
                    name: 'reactive_power_q_p_load_y3_aces',
                    code: 'reactive_power_q_p_load_y3',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(P) Response Time',
                    name: 'reactive_power_q_p_response_time_aces',
                    code: 'reactive_power_q_p_response_time',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Reactive Power Q SetPoint',
            children: [
                {
                    title: 'Q(SetPoint)',
                    name: 'reactive_power_q_set_point_flag_aces',
                    code: 'reactive_power_q_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(Setpoint) Reactive Power Mode',
                    name: 'reactive_power_q_set_point_reactive_power_mode_aces',
                    code: 'reactive_power_q_set_point_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(Setpoint) Apparent Power Priority',
                    name: 'reactive_power_q_set_point_apparent_power_priority_aces',
                    code: 'reactive_power_q_set_point_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(SetPoint) Value(Q/S):',
                    name: 'reactive_power_q_set_point_value_aces',
                    code: 'reactive_power_q_set_point_value',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(Setpoint) Response Time',
                    name: 'reactive_power_q_set_point_response_time_aces',
                    code: 'reactive_power_q_set_point_response_time',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Reactive Power Q(U)',
            children: [
                {
                    title: 'Q(U)',
                    name: 'reactive_power_q_u_flag_aces',
                    code: 'reactive_power_q_u_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Reactive Power Mode',
                    name: 'reactive_power_q_u_reactive_power_mode_aces',
                    code: 'reactive_power_q_u_reactive_power_mode',
                    type: 'radio',
                    options: [
                        { text: 'W Max Pct', value: 0 },
                        { text: 'Var Max Pct', value: 1 },
                        { text: 'Var Aval Pct', value: 2 },
                        { text: 'Va Max Pct', value: 3 },
                        { text: 'Vars', value: 4 },
                    ],
                    maxColumnNum: 5,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Apparent Power Priority',
                    name: 'reactive_power_q_u_apparent_power_priority_aces',
                    code: 'reactive_power_q_u_apparent_power_priority',
                    type: 'radio',
                    options: [
                        { text: 'Reactive Power', value: 1 },
                        { text: 'Active Power', value: 0 },
                    ],
                    maxColumnNum: 3,
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Fixed Vref Select',
                    name: 'reactive_power_q_u_fixed_vref_select_aces',
                    code: 'reactive_power_q_u_fixed_vref_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Q(U) Vref',
                    name: 'reactive_power_q_u_vref_aces',
                    code: 'reactive_power_q_u_vref',
                    type: 'number',
                    min: '228.0',
                    max: '252.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(228).max(252).unitNumber(0.1, 228),
                },
                {
                    title: 'Auto Vref Avg Time',
                    name: 'reactive_power_auto_vref_avg_time_aces',
                    code: 'reactive_power_auto_vref_avg_time',
                    type: 'number',
                    min: '300.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => defaultValidate().min(300).max(5000).unitNumber(0.1, 300),
                },
                {
                    title: 'X1: Voltage',
                    name: 'reactive_power_q_u_x1_aces',
                    code: 'reactive_power_q_u_x1',
                    type: 'number',
                    min: '184.8',
                    max: '247.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(184.8).max(247.2).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y1: Reactive',
                    name: 'reactive_power_q_u_y1_aces',
                    code: 'reactive_power_q_u_y1',
                    type: 'number',
                    min: '0.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(0).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X2: Voltage',
                    name: 'reactive_power_q_u_x2_aces',
                    code: 'reactive_power_q_u_x2',
                    type: 'number',
                    min: '220.8',
                    max: '252.0',
                    unit: 'V',
                    schema: () => defaultValidate().min(220.8).max(252).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y2: Reactive',
                    name: 'reactive_power_q_u_y2_aces',
                    code: 'reactive_power_q_u_y2',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X3: Voltage',
                    name: 'reactive_power_q_u_x3_aces',
                    code: 'reactive_power_q_u_x3',
                    type: 'number',
                    min: '228.0',
                    max: '259.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(228).max(259.2).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y3: Reactive',
                    name: 'reactive_power_q_u_y3_aces',
                    code: 'reactive_power_q_u_y3',
                    type: 'number',
                    min: '-100.00',
                    max: '100.00',
                    schema: () => defaultValidate().min(-100).max(100).unitNumber(0.01, -100),
                },
                {
                    title: 'X4: Voltage',
                    name: 'reactive_power_q_u_x4_aces',
                    code: 'reactive_power_q_u_x4',
                    type: 'number',
                    min: '232.8',
                    max: '295.2',
                    unit: 'V',
                    schema: () => defaultValidate().min(232.8).max(295.2).unitNumber(0.1, 211.2),
                },
                {
                    title: 'Y4: Reactive',
                    name: 'reactive_power_q_u_y4_aces',
                    code: 'reactive_power_q_u_y4',
                    type: 'number',
                    min: '-100.00',
                    max: '0.00',
                    schema: () => defaultValidate().min(-100).max(0).unitNumber(0.01, -100),
                },
                {
                    title: 'Q(U) Response Time',
                    name: 'reactive_power_q_u_response_time_aces',
                    code: 'reactive_power_q_u_response_time',
                    type: 'number',
                    min: '1000',
                    max: '90000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(1000).max(90000),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Dynamic Reactive Current Support',
            children: [
                {
                    title: 'R(U)',
                    name: 'dynamic_reactive_current_flag_aces',
                    code: 'dynamic_reactive_current_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'ArGraMod',
                    name: 'dynamic_reactive_current_ar_gra_mod_aces',
                    code: 'dynamic_reactive_current_ar_gra_mod',
                    type: 'selector',
                    options: [
                        { text: 'Undefined', value: 0 },
                        { text: 'Basic', value: 1 },
                        { text: 'Alternatice', value: 2 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'X1: DbVMin',
                    name: 'dynamic_reactive_current_db_v_min_aces',
                    code: 'dynamic_reactive_current_db_v_min',
                    type: 'number',
                    min: '-50.00',
                    max: '0.00',
                    unit: '%',
                    schema: () => defaultValidate().min(-50.0).max(0).unitNumber(0.01, -50.0),
                },
                {
                    title: 'X2: DbVMax',
                    name: 'dynamic_reactive_current_db_v_max_aces',
                    code: 'dynamic_reactive_current_db_v_max',
                    type: 'number',
                    min: '0.00',
                    max: '20.00',
                    unit: '%',
                    schema: () => defaultValidate().min(0).max(20).unitNumber(0.01, 0),
                },
                {
                    title: 'ArGraSag(Capacitive)',
                    name: 'dynamic_reactive_current_ar_gra_sag_aces',
                    code: 'dynamic_reactive_current_ar_gra_sag',
                    type: 'number',
                    min: '0.00',
                    max: '60.00',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(60).unitNumber(0.01, 0),
                },
                {
                    title: 'ArGraSwell(Inductive)',
                    name: 'dynamic_reactive_current_ar_gra_swell_aces',
                    code: 'dynamic_reactive_current_ar_gra_swell',
                    type: 'number',
                    min: '0.00',
                    max: '60.00',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(60).unitNumber(0.01, 0),
                },
                {
                    title: 'HoldTmms',
                    name: 'dynamic_reactive_current_hold_tmms_aces',
                    code: 'dynamic_reactive_current_hold_tmms',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
                {
                    title: 'FilterTms',
                    name: 'dynamic_reactive_current_filter_tms_aces',
                    code: 'dynamic_reactive_current_filter_tms',
                    type: 'number',
                    min: '1',
                    max: '10',
                    unit: 's',
                    schema: () => defaultValidate().integer().min(1).max(10),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] DC_Injection Control',
            children: [
                {
                    title: 'Detection Enable',
                    name: 'dc_injection_flag_aces',
                    code: 'dc_injection_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Fault_Level1',
                    name: 'dc_injection_detection_level1_aces',
                    code: 'dc_injection_detection_level1',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Fault_Time1',
                    name: 'dc_injection_detection_time1_aces',
                    code: 'dc_injection_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
                {
                    title: 'Fault_Level2',
                    name: 'dc_injection_detection_level2_aces',
                    code: 'dc_injection_detection_level2',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Fault_Time2',
                    name: 'dc_injection_detection_time2_aces',
                    code: 'dc_injection_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(10000),
                },
                {
                    title: 'Control Enable',
                    name: 'dc_injection_control_flag_aces',
                    code: 'dc_injection_control_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Control_Level',
                    name: 'dc_injection_control_level_aces',
                    code: 'dc_injection_control_level',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] RCMU Control',
            children: [
                { title: 'Sudden', type: 'header', code: 'active_power_flag', name: 'sudden' },
                {
                    title: 'Enable',
                    name: 'rcmu_sudden_flag_aces',
                    code: 'rcmu_sudden_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Level1',
                    name: 'rcmu_sudden_detection_level1_aces',
                    code: 'rcmu_sudden_detection_level1',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Time1',
                    name: 'rcmu_sudden_detection_time1_aces',
                    code: 'rcmu_sudden_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Level2',
                    name: 'rcmu_sudden_detection_level2_aces',
                    code: 'rcmu_sudden_detection_level2',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Time2',
                    name: 'rcmu_sudden_detection_time2_aces',
                    code: 'rcmu_sudden_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Level3',
                    name: 'rcmu_sudden_detection_level3_aces',
                    code: 'rcmu_sudden_detection_level3',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Time3',
                    name: 'rcmu_sudden_detection_time3_aces',
                    code: 'rcmu_sudden_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                { title: 'Continuous', type: 'header', code: 'rcmu_continuous_flag', name: 'continuous' },
                {
                    title: 'Enable',
                    name: 'rcmu_continuous_flag_aces',
                    code: 'rcmu_continuous_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Level',
                    name: 'rcmu_continuous_detection_level_aces',
                    code: 'rcmu_continuous_detection_level',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
                {
                    title: 'Time',
                    name: 'rcmu_continuous_detection_time_aces',
                    code: 'rcmu_continuous_detection_time',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => defaultValidate().integer().min(0).max(500),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Anti_Islanding Control',
            children: [
                {
                    title: 'Active Method Detection',
                    name: 'anti_islanding_active_method_detection_aces',
                    code: 'anti_islanding_active_method_detection',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Passive Method Detection',
                    name: 'anti_islanding_passive_method_detection_aces',
                    code: 'anti_islanding_passive_method_detection',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Freq Feedback Function',
                    name: 'anti_islanding_freq_feedback_function_aces',
                    code: 'anti_islanding_freq_feedback_function',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Step Injection Function',
                    name: 'anti_islanding_step_injection_function_aces',
                    code: 'anti_islanding_step_injection_function',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Reactive Power Suppression Function',
                    name: 'anti_islanding_reactive_power_suppression_aces',
                    code: 'anti_islanding_reactive_power_suppression',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Gain1',
                    name: 'anti_islanding_gain1_aces',
                    code: 'anti_islanding_gain1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    schema: () => defaultValidate().min(0).max(10000),
                },
                {
                    title: 'Gain2',
                    name: 'anti_islanding_gain2_aces',
                    code: 'anti_islanding_gain2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    schema: () => defaultValidate().min(0).max(10000),
                },
                {
                    title: 'Freq. Feedback - Qmax',
                    name: 'anti_islanding_freq_feedback_qmax_aces',
                    code: 'anti_islanding_freq_feedback_qmax',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Step Injection - Qmax',
                    name: 'anti_islanding_step_injection_qmax_aces',
                    code: 'anti_islanding_step_injection_qmax',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => defaultValidate().min(0).max(1).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Gradient Control',
            children: [
                {
                    title: 'Enable',
                    name: 'gradient_flag_aces',
                    code: 'gradient_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Energy Source Change Enable',
                    name: 'gradient_energy_source_change_aces',
                    code: 'gradient_energy_source_change',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Unit Reference Select ',
                    name: 'gradient_unit_reference_select_aces',
                    code: 'gradient_unit_reference_select',
                    type: 'radio',
                    options: [
                        { text: 'Irated', value: 0 },
                        { text: 'Prated', value: 1 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Active Power Gradient',
                    name: 'gradient_active_power_gradient_aces',
                    code: 'gradient_active_power_gradient',
                    type: 'number',
                    min: '0.1',
                    max: '100.00',
                    unit: '%W/s',
                    schema: () => defaultValidate().min(0.1).max(100).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Derating Control',
            children: [
                {
                    title: 'Enable',
                    name: 'derating_flag_aces',
                    code: 'derating_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => defaultValidate(),
                },
                {
                    title: 'Start_Temp',
                    name: 'derating_start_temp_aces',
                    code: 'derating_start_temp',
                    type: 'number',
                    min: '0.0',
                    max: '150.0',
                    unit: UNIT.CELSIUS,
                    schema: () => defaultValidate().min(0).max(150).unitNumber(0.1),
                },
                {
                    title: 'Value',
                    name: 'derating_value_aces',
                    code: 'derating_value',
                    type: 'number',
                    min: '0.0',
                    max: '60.0',
                    unit: '%',
                    schema: () => defaultValidate().min(0).max(60).unitNumber(0.1),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Re-connection Time',
            children: [
                {
                    title: 'Time',
                    name: 'inverter_reconnection_time_aces',
                    code: 'inverter_reconnection_time',
                    type: 'number',
                    min: '0',
                    max: '600',
                    unit: 'sec',
                    schema: () => defaultValidate().integer().min(0).max(600),
                },
            ],
        },
        {
            groupTitle: '[AC Coupled ESS + HUB] Randomized delay Time',
            children: [
                {
                    title: 'Randomized delay Time',
                    name: 'inverter_randomized_delay_time_aces',
                    code: 'inverter_randomized_delay_time',
                    type: 'number',
                    min: '0',
                    max: '1000',
                    unit: 'sec',
                    schema: () => defaultValidate().integer().min(0).max(1000),
                },
            ],
        },
    ];
};
export const initACSystemBasicSettingsValue = {
    msp_busbar_protection_flag: 0,
    backfeed_limit_option: 1,
    msp_msp_breaker_rating: 0,
    msp_msp_busbar_rating: 0,
    msp_der_breaker_rating: 0,
    msp_pv_breaker_rating: 0,
    msp_ess_breaker_rating: 0,
    pv_feed_in_limit_flag: 0,
    pv_feed_in_limit_w: 0,
    accb_consumption_ct_installed_point: 0,
    accb_extended_production_ct_flag: 0,
    accb_polarity_reverse_production_ct: 0,
    accb_polarity_reverse_ext_production_ct: 0,
    accb_polarity_reverse_consumption_l1_ct: 0,
    accb_polarity_reverse_consumption_l2_ct: 0,
    energy_policy: 0,
    version: '',
    site_info_backup_config_type: 0,
};
// TODO: R05 사용하지 않을 때 삭제 예정
export const initACSystemBasicSettingsValueForR05 = {
    accb_backfeed_limit_flag: 0,
    accb_backfeed_limit_option: 0,
    accb_backfeed_limit: 0,
    msp_breaker_rating: 0,
    msp_busbar_rating: 0,
    pv_feed_in_limit_flag: 0,
    pv_feed_in_limit_w: 0,
    accb_consumption_ct_installed_point: 0,
    accb_extended_production_ct_flag: 0,
    accb_polarity_reverse_production_ct: 0,
    accb_polarity_reverse_ext_production_ct: 0,
    accb_polarity_reverse_consumption_l1_ct: 0,
    accb_polarity_reverse_consumption_l2_ct: 0,
    accb_external_ct_installed_point: 0,
    energy_policy: 0,
    version: '',
    // FIXME: 화면에서의 처리를 위한 임시 작업으로 프로토콜 정의 후 항목명 수정 필요
    site_info_backup_config_type: 0,
};
export const initACSystemEngineeringSettingsValue = {
    inverter_max_output_power: 0,
    inverter_grid_target_frequency: 0,
    inverter_external_generator_mode: 0,
    inverter_permit_service_mode_select: 0,
    inverter_enter_service_randomized_delay: 0,
    inverter_import_power_limit: 0,
    inverter_export_power_limit: 0,
    battery_charge_limit: 0,
    battery_discharge_limit: 0,
    battery_user_soc_min: 0,
    battery_user_soc_max: 0,
    battery_hysteresis_low: 0,
    battery_hysteresis_high: 0,
    hub_external_inverter_flag: 0,
    hub_external_inverter_nameplate_power: 0,
    hub_external_inverter2_flag: 0,
    hub_external_inverter2_nameplate_power: 0,
    hub_generator_flag: 0,
    hub_generator_remote_on: 0,
    hub_generator_nameplate_power: 0,
    hub_msp_current_limit: 0,
    hub_external_ct_installed_point: 0,
    ul3141_ess_mode: 0,
    ul1741_flag: 0,
    external_control_connection: 0,
    auto_fw_update_flag: 0,
    pcs_debug_mode: 0,
    log_level_process_mgr: 0,
    log_level_system_log: 0,
    log_level_fota: 0,
    log_level_power_control: 0,
    log_level_ess_mgr: 0,
    log_level_cloud_mgr: 0,
    log_level_meter_mgr: 0,
    log_level_gateway_mgr: 0,
    log_level_data_accumulator: 0,
    log_level_db_mgr: 0,
    log_level_web_engine: 0,
    log_level_mlpe_mgr: 0,
    log_level_sys_mgr: 0,
    log_level_power_scheduler: 0,
    log_level_energy_link: 0,
    log_level_diag_monitor: 0,
    log_level_csip_mgr: 0,
    log_level_energy_optimizer: 0,
    log_level_ipc_bridge: 0,
    log_level_energy_monitor: 0,
    log_level_energy_scheduler: 0,
    debug_mode_mqtt: 0,
    debug_mode_modbus: 0,
    site_configuration_disable_mode: 0,
};
