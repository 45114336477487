import isNil from 'lodash-es/isNil';
import { UNIT } from '@hems/util/src/constant';
import { isNull } from '@hems/util/src/helper/helper';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { isUnitValueType } from '@hems/util/src/helper/tsguardHelper';
export const getTooltipFormattedNumber = (pointNumber, baseUnit) => {
    const { formattedNumber, unit } = formatUnitNumber(pointNumber, baseUnit);
    return `${formattedNumber} ${unit}`;
};
export const graphTooltipFormatter = (tooltip) => {
    if (isNil(tooltip.chart.hoverPoints))
        return '';
    const xValue = tooltip.chart.hoverPoints[0].category;
    const tooltipBySeries = [];
    tooltip.chart.hoverPoints.forEach((point) => {
        if (!isNil(point.y)) {
            const baseUnit = point.series.yAxis.userOptions.id ?? '';
            tooltipBySeries.push(`<span style="color:${point.color}">●</span> ${point.series.name}: ${getTooltipFormattedNumber(point.y, isUnitValueType(baseUnit) ? baseUnit : UNIT.WATT)}`);
        }
    });
    return `${xValue}<br />${tooltipBySeries.join('<br />')}`;
};
const splitTooltipText = (text) => {
    return [text.slice(0, -3), text.slice(-3)];
};
export const ACModuleChartTooltipFormatter = (tooltipObject) => {
    if (!tooltipObject.points)
        return String(tooltipObject.x);
    const tooltipText = tooltipObject.points
        .filter((point) => !isNull(point.y))
        .map((point) => {
        const ACModuleChartTooltipText = point.series.userOptions.custom?.tooltipText;
        if (!ACModuleChartTooltipText)
            return '';
        const baseUnit = isUnitValueType(point.series.userOptions.custom?.unit ?? '')
            ? point.series.userOptions.custom?.unit
            : UNIT.WATT;
        const [textWithoutLast3Chars, last3Chars] = splitTooltipText(ACModuleChartTooltipText);
        return `<br /><span style="color:${point.color}">●</span> ${textWithoutLast3Chars}<strong>${last3Chars}</strong>: ${getTooltipFormattedNumber(point.y, baseUnit)}`;
    })
        .join('');
    return `<div style='width: 310px; overflow-y: auto; max-height: 300px;'>${tooltipObject.x}${tooltipText}</div>`;
};
export const integratedGraphTooltipFormatter = (tooltipObject) => {
    const x = tooltipObject.point.x;
    const chart = tooltipObject.series.chart;
    let tooltipText = `<div style="line-height: 1rem">${tooltipObject.x}`;
    chart.series.forEach((series) => {
        if (series.visible) {
            const point = series.points.filter((point) => point.x === x)[0];
            if (!isNull(point?.y)) {
                const baseUnit = point.series.yAxis.userOptions.id ?? '';
                tooltipText = `${tooltipText}<br><span style="color: ${point.color}">● </span>
        ${series.name}: ${getTooltipFormattedNumber(point.y, isUnitValueType(baseUnit) ? baseUnit : UNIT.WATT)}`;
            }
        }
    });
    tooltipText = `${tooltipText}</div>`;
    return tooltipText;
};
