import { nextTick, defineComponent, reactive, computed, onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Accordion, ToastGrid, Pagenation, Panel, withLoading, SettingTable, useMessageBox, SettingTableRow, } from '@hems/component';
import { getColumns } from '@hems/component/src/installationHistory/gridConfig';
import { DeviceServiceAdmin } from '@hems/service';
import { Helper, DateHelper, useLanguage, useGenType, useInstallationHistory } from '@hems/util';
import { HISTORY_INFO_CHANGE_TYPE_CODE, HISTORY_DEVICE_TYPE_CD } from '@hems/util/src/constant';
import ListSize from '../../src/selectors/ListSize/ListSize.vue';
export default defineComponent({
    name: 'InstallationHistory',
    components: {
        Accordion,
        SettingTable,
        SettingTableRow,
        Panel,
        ToastGrid,
        Pagenation,
        ListSize,
    },
    props: {
        allDeviceInstallHistoryList: {
            type: Array,
            required: false,
            default: () => [],
        },
        foxInvHistoryList: {
            type: Array,
            required: false,
            default: () => [],
        },
        allEssHistoryList: {
            type: Array,
            required: false,
            default: () => [],
        },
        hubHistoryList: {
            type: Array,
            required: false,
            default: () => [],
        },
        allMiHistoryList: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    setup(props) {
        onMounted(() => {
            loadData();
        });
        const { t } = useI18n();
        const store = useStore();
        const route = useRoute();
        const { isNotDeleteChangedTypeCode } = useInstallationHistory();
        const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
        const { languageCode } = useLanguage();
        const condition = reactive({
            pageNum: route.query.pageNum ? Number(route.query.pageNum) : 1,
            listSize: route.query.listSize ? Number(route.query.listSize) : 10,
            sortColumn: 'create_dt desc',
        });
        const showGrid = ref(false);
        const toastGrid = reactive({
            gridOptions: {
                columns: computed(() => getColumns(t)),
                data: [],
                sortState: {
                    columnName: 'create_dt_str',
                    ascending: false,
                    multiple: false,
                },
                options: {
                    bodyHeight: 'auto',
                },
            },
        });
        const { isACSystem, genType } = useGenType();
        const hasHistoryInfo = computed(() => props.allDeviceInstallHistoryList.filter((deviceHistoryList) => deviceHistoryList.length).length ||
            props.foxInvHistoryList.length ||
            props.hubHistoryList.length ||
            props.allMiHistoryList.length ||
            props.allEssHistoryList.filter((deviceHistoryList) => deviceHistoryList.length).length);
        const getESSNumber = (index) => {
            if (props.allEssHistoryList.length <= 1) {
                return '';
            }
            return index + 1;
        };
        const dateFormatConfig = {
            isTime: true,
            needUtcConvert: true,
            needTimezoneConvert: true,
            timezone: store.state.device.device_info.timezone_id,
        };
        const getFormattedDateString = (date) => DateHelper.getLocalDate(date, dateFormatConfig);
        const addTypeCountInfoToHistoryList = (historyList) => {
            let replaceCount = 0;
            let deleteCount = 0;
            return (historyList
                // TODO DELETE 타입 작업 시 아래 필터링 코드 제거
                .filter(isNotDeleteChangedTypeCode)
                .map((history) => {
                if (history.change_type_cd === HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE ||
                    history.change_type_cd === HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE_MI) {
                    replaceCount += 1;
                }
                else if (history.change_type_cd === HISTORY_INFO_CHANGE_TYPE_CODE.DELETE) {
                    deleteCount += 1;
                }
                return {
                    ...history,
                    replaceNum: replaceCount,
                    deleteNum: deleteCount,
                };
            }));
        };
        const getChangedTypeCodeText = ({ change_type_cd, replaceNum, deleteNum, }) => {
            switch (change_type_cd) {
                case HISTORY_INFO_CHANGE_TYPE_CODE.INSERT:
                    return t('device.initial_installed');
                case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE:
                case HISTORY_INFO_CHANGE_TYPE_CODE.REPLACE_MI:
                    return t('device.of_replaced', { num: replaceNum });
                // TODO DELETE는 추후 정의
                case HISTORY_INFO_CHANGE_TYPE_CODE.DELETE:
                    return `Delete #${deleteNum}`;
            }
        };
        const getDeviceTextByDeviceTypeCd = (deviceTypeCd) => {
            switch (deviceTypeCd) {
                case HISTORY_DEVICE_TYPE_CD.AC_COMB:
                    return t('device.ac_combiner');
                case HISTORY_DEVICE_TYPE_CD.AC_ESS:
                    return t('device.ess');
                case HISTORY_DEVICE_TYPE_CD.FOX_GW_EMS:
                    return t('device.hub');
                case HISTORY_DEVICE_TYPE_CD.FOX_INV:
                case HISTORY_DEVICE_TYPE_CD.GW_INV:
                default:
                    return t('device.inverter');
            }
        };
        const totalCnt = computed(() => toastGrid.gridOptions.data[0]?.total_records || 0);
        const makeFormatList = (data) => {
            const dateFormatConfig = { isTime: true, needUtcConvert: true };
            return (data.map((item) => ({
                ...item,
                create_dt_str: item.create_dt ? DateHelper.getLocalDate(item.create_dt, dateFormatConfig) : '',
                create_dt_utc_str: item.create_dt_utc ? DateHelper.getLocalDate(item.create_dt_utc, dateFormatConfig) : '',
            })) || []);
        };
        const batteryList = computed(() => {
            const dateFormatConfig = { isTime: true, needUtcConvert: true };
            return (toastGrid.gridOptions.data.map((item) => ({
                ...item,
                create_dt_str: item.create_dt ? DateHelper.getLocalDate(item.create_dt, dateFormatConfig) : '',
                create_dt_utc_str: item.create_dt_utc ? DateHelper.getLocalDate(item.create_dt_utc, dateFormatConfig) : '',
            })) || []);
        });
        const loadData = withLoading(async () => {
            try {
                const getBatteryHistData = await deviceService.getBatteryHistoryInfo(store.state.device.device_info.device_id, condition);
                toastGrid.gridOptions.data = getBatteryHistData;
            }
            catch (e) {
                toastGrid.gridOptions.data = [];
                condition.pageNum = 1;
                console.error(e);
                messageBox.alert(t('message.error_data_info')).open();
            }
        });
        const messageBox = useMessageBox();
        const convertModuleInfoJson = (batteryHistoryInfo) => {
            if (batteryHistoryInfo.module) {
                const moduleInfoJSON = JSON.parse(batteryHistoryInfo.module);
                let moduleInfoCSV = '';
                for (let i = 0; i < moduleInfoJSON.length; i++) {
                    let moduleMessage = `Module #${i + 1}: { id: ${moduleInfoJSON[i].id} | sn: ${moduleInfoJSON[i].sn} }`;
                    moduleMessage = i === 0 ? moduleMessage : ' | '.concat(moduleMessage);
                    moduleInfoCSV += moduleMessage;
                }
                batteryHistoryInfo.module = moduleInfoCSV;
            }
            return batteryHistoryInfo;
        };
        const onDownload = withLoading(async () => {
            try {
                const data = await deviceService.batteryHistoryExcelDownloads(store.state.device.device_info.device_id, condition);
                const resData = makeFormatList(data.map(convertModuleInfoJson));
                Helper.downloadCSV(resData, toastGrid.gridOptions.columns, 'BatteryList');
            }
            catch (e) {
                console.error(e);
                messageBox.alert(t('message.error_download')).open();
            }
        });
        const onListSizeChange = () => {
            condition.pageNum = 1;
            loadData();
        };
        const onPaginationChange = () => {
            loadData();
        };
        watch(() => [condition.sortColumn], () => {
            loadData();
        });
        watch(() => languageCode.value, () => {
            rerenderDataGrid();
        });
        function rerenderDataGrid() {
            showGrid.value = false;
            nextTick(() => (showGrid.value = true));
        }
        showGrid.value = true;
        function gridEventsHandler(gridEvent) {
            gridEvent.stop(); // tui-grid에서 자체 제공하는 sorting을 사용하지 않기 위해서
            const { ascending } = gridEvent;
            let { columnName, multiple } = gridEvent;
            toastGrid.gridOptions.sortState = {
                columnName,
                ascending,
                multiple,
            };
            if (columnName === 'create_dt_str') {
                columnName = 'create_dt';
            }
            condition.sortColumn = `${columnName} ${ascending ? 'asc' : 'desc'}`;
        }
        return {
            condition,
            toastGrid,
            showGrid,
            genType,
            isACSystem,
            HISTORY_DEVICE_TYPE_CD,
            hasHistoryInfo,
            formatNumber: Helper.formatNumber,
            batteryList,
            getDeviceTextByDeviceTypeCd,
            getESSNumber,
            totalCnt,
            onListSizeChange,
            onPaginationChange,
            onDownload,
            getFormattedDateString,
            getChangedTypeCodeText,
            addTypeCountInfoToHistoryList,
            events: {
                beforeSort: gridEventsHandler,
            },
        };
    },
});
