import { AuthHelper } from '@hems/util';
// eslint-disable-next-line import/prefer-default-export
export const useLogout = () => {
    const logout = () => {
        AuthHelper.removeAccessToken();
        window.axiosInstance.clearAccessToken();
        // 현재 상태를 히스토리 스택에 저장
        history.pushState(null, '', window.location.href);
        // bfcache 방지를 위해 location.replace 사용
        location.replace('/');
    };
    return { logout };
};
