import { defineComponent, onMounted, reactive, ref } from 'vue';
import { PopupWrapper, BasicInput } from '@hems/component';
import { INPUT_TYPE } from '@hems/util/src/constant/input';
import { Image, GoogleLoginButton } from '@/components';
export default defineComponent({
    name: 'LoginPopup',
    components: {
        PopupWrapper,
        BasicInput,
        Image,
        GoogleLoginButton,
    },
    emits: ['close', 'login', 'findIdPw', 'join', 'googleLogin', 'facebookLogin', 'appleLogin'],
    setup(_, { emit }) {
        const usernameEl = ref(null);
        const state = reactive({
            username: null,
            password: null,
        });
        const closePopup = (e) => {
            e.preventDefault();
            emit('close');
        };
        function onLogin(e) {
            e.preventDefault();
            const { username, password } = state;
            emit('login', { username, password });
        }
        onMounted(() => {
            usernameEl.value?.focus();
        });
        return {
            state,
            usernameEl,
            closePopup,
            onLogin,
            INPUT_TYPE,
        };
    },
});
