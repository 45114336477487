import { defineComponent, ref } from 'vue';
import { useModelWrapper } from '@hems/util';
export default defineComponent({
    name: 'TabNew',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        tabList: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const tabValue = useModelWrapper(props, emit);
        const selectedTabValue = ref(tabValue);
        const switchTab = (tabValue) => {
            selectedTabValue.value = tabValue;
        };
        return {
            switchTab,
            selectedTabValue,
        };
    },
});
