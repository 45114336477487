import { Helper, pvmoduleSettingsHelper, SettingHelper } from '@hems/util';
import { SETTING_TARGET } from '@hems/util/src/constant';
import { getSettingSaveParams } from '@hems/util/src/helper/settingHelper';
// TODO: packages 내의 파일에서 절대경로, 상대경로 사용에 대한 논의 결과에 따라 path 수정
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { Service } from '../Service';
export default class DeviceService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getImageBySiteId(siteId) {
        return await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/images`), {}, {
            responseType: 'blob',
        });
    }
    async saveImageBySiteId(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/images`), params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    }
    async checkDeviceId(deviceId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/validations/${deviceId}/ems-device-id`));
        return data;
    }
    // 모니터링 리스트 조회
    async getMonitoringList(params) {
        const { data } = await this.get(apiWrapper.stateApi('/devices/telemetries/renewal'), params);
        return data;
    }
    // AC System - 모니터링 Site 목록 조회
    async getMonitoringSiteList(params) {
        const { data } = await this.get(apiWrapper.stateApi('/devices/telemetries/multi-inverter/sites'), params);
        return data;
    }
    // AC System - 모니터링 EMS 목록 조회
    async getMonitoringDeviceList(params) {
        const { data } = await this.get(apiWrapper.stateApi('/devices/telemetries/multi-inverter/devices'), params);
        return data;
    }
    // 모니터링 상세 - Dashboard - 프로파일, Inventory
    async getDeviceProfile(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/telemetries/${siteId}`));
        return data;
    }
    // 모니터링 상세 - Setting - Inatallation
    async getInstallationInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/installations`));
        return data;
    }
    // 모니터링 상세 - Battery History
    async getBatteryHistoryInfo(deviceId, param) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${deviceId}/battery`), param);
        return data;
    }
    // 모니터링 상세 - Battery History Excel download
    async batteryHistoryExcelDownloads(deviceId, param) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${deviceId}/battery/excel`), param);
        return data;
    }
    // 모니터링 상세 - Setting - Basic, Advanced, TOU
    async getSettingInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/configurations`), undefined, {
            headers: getApiVersionHeader(),
        });
        return data;
    }
    // Array&Mapping - Basic Device Info
    getBasicDeviceInfo = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/resources/sites/${siteId}/devices`));
        return data;
    };
    // 에러 모듈 목록
    async getErrorDeviceList(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/alarms/${siteId}/modules`));
        return data;
    }
    // 장비 목록
    async getDeviceList() {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/excel-files'));
        return data;
    }
    // 그리드 코드 목록
    async getGridCodeGen2(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/grid-codes'), params);
        return data;
    }
    async getGridCodeGen3(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/grid-codes'), params);
        return data;
    }
    async getGridCodeACSystem(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/grid-codes'), params);
        return data;
    }
    async saveLocationInfo(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/locations`), params);
        return data;
    }
    //
    async controlGen2Setting(deviceId, param) {
        const saveParam = {
            msgType: param.msg_type,
        };
        if (param.msg_type.indexOf('BS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN2', 'BS', param)));
        if (param.msg_type.indexOf('AS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN2', 'AS', param)));
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`), saveParam);
        return data;
    }
    async controlGen3Setting(deviceId, param) {
        const saveParam = {
            msgType: param.msg_type,
        };
        if (param.msg_type.indexOf('BS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'BS', param)));
        if (param.msg_type.indexOf('AS') !== -1) {
            const tempParam = Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'AS', param));
            const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
            const gridCode = `${tempParam.gridCode}`;
            delete tempParam.gridCode;
            Object.assign(saveParam, { gridCode }, { gen3AdvancedSettings: tempParam }, installSettingParam);
        }
        if (param.msg_type.indexOf('TOU') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'TOU', param)));
        if (param.msg_type.indexOf('ES') !== -1) {
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('GEN3', 'ES', param)));
        }
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`), saveParam);
        return data;
    }
    async controlFoxSetting(deviceId, param) {
        const saveParam = {
            msgType: param.msg_type,
        };
        if (param.msg_type.indexOf('BS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'BS', param)));
        if (param.msg_type.indexOf('ES') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'ES', param)));
        if (param.msg_type.indexOf('TOU') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('FOX', 'TOU', param)));
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`), saveParam);
        return data;
    }
    async controlACSystemSetting(deviceId, param) {
        const saveParam = {
            msgType: param.msg_type,
        };
        if (param.msg_type.indexOf('BS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'BS', param)));
        if (param.msg_type.indexOf('AS') !== -1) {
            const tempParam = Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'AS', param));
            const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
            const gridCode = `${tempParam.gridCode}`;
            delete tempParam.gridCode;
            Object.assign(saveParam, { gridCode }, tempParam, installSettingParam);
        }
        if (param.msg_type.indexOf('TOU') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'TOU', param)));
        if (param.msg_type.indexOf('ES') !== -1) {
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'ES', param)));
        }
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`), saveParam);
        return data;
    }
    /** AC System Configuration with SiteId */
    async controlACSystemSettingWithSiteId(siteId, param, target = SETTING_TARGET.SITE) {
        const saveParam = getSettingSaveParams(param, target);
        const { data } = await this.post(apiWrapper.controlApi(`/devices/site/${siteId}/configurations/sync`), saveParam);
        return data;
    }
    async checkDeviceConnection(deviceId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/connections/${deviceId}/status-devices`), undefined, {
            headers: getApiVersionHeader(),
        });
        return data;
    }
    async checkDeviceConnectionBySite(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/connections/${siteId}/status`));
        return data;
    }
    // deletion/rollback 구별
    async checkDeleteStatus(siteId, params) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/installations/admin/delete/status/${siteId}`), params);
        return data;
    }
    async replaceDevice(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/installations/ems/${siteId}`), params);
        return data;
    }
    async removeDevice(siteId, params) {
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/ems/${siteId}`), undefined, { data: params });
        return data;
    }
    async deleteDevice(deviceId) {
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/admin/delete/${deviceId}`));
        return data;
    }
    async rollbackDevice(deviceId) {
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/admin/rollback/${deviceId}`));
        return data;
    }
    // 장비 추가/교체/삭제 관련
    async getBatteryInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`));
        return data;
    }
    async getAssignedDevice(siteId, prnDeviceId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/assigned-devices`), { prnDeviceId });
        return data;
    }
    async createSubDevice(siteId, params) {
        const { data } = await this.post(apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`), params);
        return data;
    }
    async createSubBattery(siteId, params) {
        const { data } = await this.post(apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`), params);
        return data;
    }
    async replaceSubDevice(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`), params);
        return data;
    }
    async replaceSubBattery(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`), params);
        return data;
    }
    async deleteSubDevice(siteId, params) {
        const { deviceTypeCd, deviceId, prnDeviceId } = params;
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/power-optimizer/primiary-board/${siteId}`), undefined, {
            data: { deviceTypeCd, deviceId, prnDeviceId },
        });
        return data;
    }
    async deleteSubBattery(siteId, params) {
        const { batteryDeviceId } = params;
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/new-batteries/${siteId}`), undefined, {
            data: { batteryDeviceId },
        });
        return data;
    }
    async getPvModuleInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/modules`));
        return data;
    }
    async replaceNMI(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/installations/nmi/${siteId}`), params);
        return data;
    }
    async removeNMI(siteId, nmiDeviceId, deviceId) {
        const { data } = await this.delete(apiWrapper.managerApi(`/devices/installations/nmi/${siteId}`), undefined, { data: { nmiDeviceId, deviceId } });
        return data;
    }
    async checkNMIDeviceId(nmi_device_id) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/validations/${nmi_device_id}/nmi-id`));
        return data;
    }
    async saveEnabledNotiAlarms(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/alarm-flags`), Helper.keyToCamelCase(params));
        return data;
    }
    async saveHomeownerInfo(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/homeowners`), params);
        return data;
    }
    async updateRetailer(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/retailers`), params);
        return data;
    }
    async updateOffGrid(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/off-grids`), Helper.keyToCamelCase(params));
        return data;
    }
    async updateTestSite(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/test-sites`), Helper.keyToCamelCase(params));
        return data;
    }
    async checkDuplicatedLocation(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/locations/validations'), params);
        return data;
    }
    async updateComments(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/comments`), params);
        return data;
    }
    async updateDynamicExport(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/dynamic-exports`), Helper.keyToCamelCase(params));
        return data;
    }
    async registerSapn(params) {
        const { data } = await this.post(apiWrapper.controlApi('/devices/lfdi'), params);
        return data;
    }
    async getInstallationSiteList(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/sites'), params);
        return data;
    }
    async getInstallationSite(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/sites`));
        return data;
    }
    async getInstallationSteps(siteId) {
        return await this.get(apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/steps`)).then((res) => {
            const data = res.data;
            return {
                ...data,
                stepData: data?.stepData ? (Helper.isJSON(data.stepData) ? JSON.parse(data.stepData) : null) : null,
            };
        });
    }
    async getInstallationDeviceInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/mobiles/pro-apps/register/${siteId}`));
        return data;
    }
    getSiteNetworkType = async (siteId) => {
        const { data } = await this.get(apiWrapper.stateApi(`/devices/telemetries/network-type/${siteId}`));
        return data;
    };
    getSettingInfoByDeviceId = async (deviceId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/id/${deviceId}/configurations`), undefined, {
            headers: getApiVersionHeader(),
        });
        return data;
    };
}
export { DeviceService };
