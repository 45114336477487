import codes from 'country-calling-code';
import countries from 'i18n-iso-countries';
import camelCase from 'lodash-es/camelCase';
import find from 'lodash-es/find';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';
import range from 'lodash-es/range';
import moment from 'moment-timezone';
import { AC_MODEL_TYPE, NUMBER_UNIT, alpha2CountryList, auAlpha2CountryList, euAlpha2CountryList, localStorageKey, usAlpha2CountryList, SORT_ORDER, INTL_LOCALE_MAP, DEFAULT_LANGUAGE, UNIT, } from '@hems/util/src/constant';
import { DEVICE_CONNECTION_STATUS } from '@hems/util/src/constant/constant';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { isLocaleValueType } from '@hems/util/src/helper/tsguardHelper';
/* eslint-disable */
export const getUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 3) | 8;
        return v.toString(16);
    });
};
export function getByteLength(s) {
    let b, i, c;
    if (s != undefined && s != '') {
        for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1)
            ;
        return b;
    }
    else {
        return 0;
    }
}
export function isNull(str) {
    return (typeof str === 'string' && str.trim() === '') || str === undefined || str === null;
}
export function padLeft(number, length, padString = '0') {
    const sign = number < 0 ? '-' : '';
    const padStrLength = length - Math.abs(number).toString().length < 0 ? 0 : length - Math.abs(number).toString().length;
    const padStr = Array(padStrLength + 1).join(padString);
    return sign + padStr + number;
}
export function setAxiosInterceptor(axiosInstance, accessToken) {
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = 'Bearer ' + accessToken;
        return config;
    }, (error) => Promise.reject(error));
}
export function formatNumber(_num, decimal = 0) {
    if (_num === undefined || _num === null)
        return null;
    const num = typeof _num === 'string' ? Number(_num) : _num;
    if (isNaN(num))
        return null;
    const number = num.toFixed(decimal);
    return number.replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    });
}
export function deepFreeze(o) {
    Object.freeze(o);
    if (o === undefined) {
        return o;
    }
    Object.getOwnPropertyNames(o).forEach(function (prop) {
        if (o[prop] !== null &&
            (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
            !Object.isFrozen(o[prop])) {
            deepFreeze(o[prop]);
        }
    });
    return o;
}
export function getPartial(o, props) {
    const retVal = {};
    for (let prop of props) {
        retVal[prop] = o[prop];
    }
    return retVal;
}
export function getNumberFormatter(langCd, options = {}) {
    return new Intl.NumberFormat(isLocaleValueType(langCd) ? INTL_LOCALE_MAP[langCd] : DEFAULT_LANGUAGE, options);
}
export function nullTo(value, defaultValue) {
    return isNil(value) ? defaultValue : value;
}
export function isEmpty(param) {
    return Object.keys(param).length === 0;
}
export function keyToCamelCase(obj) {
    const retObj = {};
    for (let key in obj) {
        const newKey = camelCase(key);
        retObj[newKey] = obj[key];
    }
    return retObj;
}
export function convertSettingParam(obj) {
    if (!obj)
        return;
    const temp = pickBy(obj, (value) => value !== undefined);
    for (let key in temp) {
        if (typeof temp[key] !== 'object') {
            temp[key] = '' + temp[key];
        }
    }
    return keyToCamelCase(temp);
}
export function codeNameToSelectorOption(codeName, t) {
    if (codeName.description) {
        return {
            text: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
            value: codeName.code,
            description: codeName.description,
        };
    }
    else {
        return {
            text: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
            value: codeName.code,
        };
    }
}
export function codeNamesToSelectorOptions(codeNames, t, first) {
    const firstItem = [];
    if (typeof first === 'boolean' && first === true) {
        firstItem.push({ text: '', value: null });
    }
    else if (typeof first === 'object') {
        firstItem.push(first);
    }
    return firstItem.concat(codeNames.map((item) => codeNameToSelectorOption(item, t)));
}
export function addSelectorOptionAtFirst(options, option = { text: 'word.select', value: '' }) {
    return [option].concat(options);
}
export function codeNameToLabelSelectorOption(codeName, t) {
    return {
        label: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
        value: codeName.code,
    };
}
export function codeNamesToLabelSelectorOptions(codeNames, t, first) {
    const firstItem = [];
    if (typeof first === 'boolean' && first === true) {
        firstItem.push({ label: '', value: null });
    }
    else if (typeof first === 'object') {
        firstItem.push(first);
    }
    return firstItem.concat(codeNames.map((item) => codeNameToLabelSelectorOption(item, t)));
}
export function addLabelSelectorOptionAtFirst(options, option = { label: 'word.select', value: '' }) {
    return [option].concat(options);
}
export function filterByKey(data, keys) {
    const retData = {};
    for (let k in data) {
        if (keys.includes(k))
            retData[k] = data[k];
    }
    return retData;
}
export function download(data, filename) {
    let fileURL = window.URL.createObjectURL(data);
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);
    fileLink.click();
}
export function convertParamToStringAttr(param) {
    const retParam = {};
    for (const k in param) {
        if (Array.isArray(param[k])) {
            retParam[k] = param[k].join(',');
            continue;
        }
        if (param[k] === null || param[k] === undefined)
            continue;
        const type = typeof param[k];
        switch (type) {
            case 'number':
            case 'boolean':
                retParam[k] = '' + param[k];
                break;
            case 'object':
                retParam[k] = JSON.stringify(param[k]);
                break;
            case 'string':
                retParam[k] = param[k];
                break;
        }
    }
    return retParam;
}
export function contains(list, value) {
    return list.some((item) => item === value);
}
export function downloadCSV(data, columns, fileName = 'export') {
    const _fileName = `${fileName}_${moment(new Date()).format('YYYYMMDDHHmmss')}`;
    const header = columns.map((item) => `"${item.header}"`).join(',');
    const headerId = columns.map((item) => item.name);
    const rows = [];
    data.forEach((item) => {
        const row = [];
        for (const id of headerId) {
            if ([
                'device_id',
                'grp_nm',
                'user_nm',
                'user_nm_dec',
                'mpn_no',
                'instl_user_id',
                'user_id',
                'comn_nm',
                'site_id',
                'battery1',
                'battery2',
                'battery3',
            ].includes(id)) {
                row.push(`"${isNull(item[id]) ? '' : item[id]}\t"`);
            }
            else {
                row.push(`"${isNull(item[id]) ? '' : item[id]}"`);
            }
        }
        rows.push(row.join(','));
    });
    const csvData = header + '\r\n' + rows.join('\r\n');
    const exportedFilenmae = _fileName + '.csv';
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    }
    else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
export function getOperStatusName(operStusStr) {
    switch (operStusStr) {
        case 'code.operstuscd.0':
            return 'device.normal';
        case 'code.operstuscd.1':
            return 'device.warning';
        case 'code.operstuscd.2':
            return 'common.error';
        case 'code.operstuscd.4':
            return 'common.incomplete';
        default:
            return '';
    }
}
export function toFixed(value, decimalPoint) {
    try {
        if (decimalPoint <= 0)
            return value.toString();
        const assistant = Math.pow(10, decimalPoint);
        return (Math.floor(value * assistant) / assistant).toFixed(decimalPoint);
    }
    catch (e) {
        console.error(e);
        return value.toString();
    }
}
export function capitalize(str) {
    return str ? str.charAt(0).toUpperCase() + str.toLocaleLowerCase().substring(1) : '';
}
export function isJSON(jsonString) {
    try {
        return JSON.parse(jsonString) && !!jsonString;
    }
    catch (e) {
        return false;
    }
}
export function getAppEnv() {
    return process.env.VUE_APP_ENV;
}
export function getEnvLocale() {
    return process.env.VUE_APP_LOCALE;
}
// ref: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!userAgent)
        return 'unknown';
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'windows';
    }
    if (/android/i.test(userAgent)) {
        return 'android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }
    return 'unknown';
}
export function extractMaxInvRefFromProductModel(productModelNm) {
    const invRefInProdModel = productModelNm?.replace(/^DC2/, '').replace(/H3$/, '').replace(/\D/g, '');
    let invRefKw = Number(invRefInProdModel?.substring(0, invRefInProdModel.length - 1));
    if (invRefKw === 460)
        invRefKw = 500;
    const expo = Number(invRefInProdModel?.substring(invRefInProdModel.length - 1));
    // TODO: QHUB0000 exception - invRefKw = 0 && expo = 0일 때  어떻게 처리할 것인지 확인
    if (invRefKw === 0 && expo === 0)
        return 30000;
    return invRefKw * Math.pow(10, expo);
}
export function inRange(value, min, max) {
    return (value - min) * (value - max) <= 0;
}
export function getDialCodeByCountry(locale) {
    const languageList = countries.getSupportedLanguages();
    languageList.forEach((item) => {
        countries.registerLocale(require(`i18n-iso-countries/langs/${item}.json`));
    });
    let countryAndLang = alpha2CountryList;
    if (locale) {
        switch (locale) {
            case 'EU':
                countryAndLang = euAlpha2CountryList;
                break;
            case 'AU':
            case 'NZ':
                countryAndLang = auAlpha2CountryList.concat(euAlpha2CountryList);
                break;
            case 'US':
                countryAndLang = usAlpha2CountryList.concat(euAlpha2CountryList);
                break;
            default:
                countryAndLang = alpha2CountryList;
        }
    }
    return countryAndLang.map((item) => ({
        name: countries.getName(item.country, item.lang),
        dial_code: `+${find(codes, { isoCode2: item.country })?.countryCodes[0]}`,
    }));
}
export function getTempertureUnit(type = 'C') {
    return type === 'F' ? '°F' : '°C';
}
export function getTemporaryPassword(length = 8) {
    // 일반 사용자 회원가입 시 패스워드 정책과 동일
    // @hems/util constant.ts: passwordPolicy = /(((?=.*[a-z])|(?=.*[A-Z])|(?=.*[0-9]))(?=.*[`~!@#$%^&*|'";:/?\\]).{8,20})/;
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`~!@#$%^&*|\'";:/?]';
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
}
export function checkValidEmsVersion(genType = 'GEN3', currentEmsVersion = '', validEmsVersion) {
    if (genType === 'GEN2')
        return null;
    if (currentEmsVersion === '')
        return null;
    const versionSeperator = currentEmsVersion[0];
    if (['R', 'Y', 'X', 'D'].includes(versionSeperator)) {
        const emsVersion = Number(currentEmsVersion.replace(/\D/g, '').substring(0, 2));
        if (emsVersion >= validEmsVersion)
            return true;
        else
            return false;
    }
    return null;
}
// FIXME: checkIsACCoupled 로 이름 변경할 것
export function checkIsAcCoupled(deviceId) {
    if (isNull(deviceId))
        return false;
    if (deviceId.substring(6, 7) === '3') {
        return true;
    }
    return false;
}
export function checkIsHybrid(deviceId) {
    if (isNull(deviceId))
        return false;
    if (deviceId.substring(6, 7) === '2') {
        return true;
    }
    return false;
}
export function checkIsHcore(productModelName) {
    if (isNull(productModelName))
        return false;
    if (productModelName.toUpperCase().startsWith('HCORE')) {
        return true;
    }
    return false;
}
export function checkIsQcore(productModelName) {
    if (isNull(productModelName))
        return false;
    if (productModelName.toUpperCase().startsWith('QCORE')) {
        return true;
    }
    return false;
}
export function getEmsVersionNumber(genType = 'GEN3', currentEmsVersion = '') {
    if (genType === 'GEN2')
        return null;
    if (currentEmsVersion === '')
        return null;
    const versionSeperator = currentEmsVersion[0];
    if (['R', 'Y', 'X', 'D'].includes(versionSeperator)) {
        const emsVersion = Number(currentEmsVersion.replace(/\D/g, '').substring(0, 2));
        return emsVersion;
    }
    return null;
}
export function isPVES(productModelNm) {
    if (productModelNm.toUpperCase().includes('PVES')) {
        return true;
    }
    return false;
}
export function getKeyByValue(obj, value) {
    const indexOfObj = Object.values(obj).indexOf(value);
    const key = Object.keys(obj)[indexOfObj];
    return key;
}
export function isEmptyDeep(obj) {
    for (let key in obj) {
        if (obj[key] instanceof Object === true) {
            if (isEmptyDeep(obj[key]) === false)
                return false;
        }
        else {
            if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0)
                return false;
        }
    }
    return true;
}
export function preventKeyEnter(event) {
    if (event.type === 'keydown') {
        if (!(event.target.type === 'textarea')) {
            event.preventDefault();
            return false;
        }
    }
    return true;
}
export function isEDF(productModelNm) {
    if (productModelNm.endsWith('E')) {
        return true;
    }
    return false;
}
export function getCategoryNameByCode(category, t) {
    const codeNames = [
        {
            code: category.slice(-1),
            name: category,
        },
    ];
    return codeNamesToSelectorOptions(codeNames, t, false)[0].text;
}
export function getLangCd() {
    return localStorage.getItem(localStorageKey.language);
}
export function convertDecimalToHex(value, digit) {
    return value.toString(16).toUpperCase().padStart(digit, '0');
}
export function getACModelType(productModelNm) {
    if (!productModelNm)
        return AC_MODEL_TYPE.AC_ESS;
    if (productModelNm.includes(AC_MODEL_TYPE.AC_ESS))
        return AC_MODEL_TYPE.AC_ESS;
    if (productModelNm.includes(AC_MODEL_TYPE.AC_COMBINER))
        return AC_MODEL_TYPE.AC_COMBINER;
    return AC_MODEL_TYPE.AC_ESS;
}
export const isEmptyArray = (arr) => {
    return arr.length === 0;
};
export function attachUnit(value, unit) {
    if (value === undefined || value === null)
        return null;
    return `${value} [${unit}]`;
}
export const attachUnitToTitle = (title, unit) => {
    return `${title} [${unit}]`;
};
export function getKwhData(arr) {
    const sum = arr.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0) * NUMBER_UNIT.THOUSAND, 0) ?? 0;
    const formattedData = formatUnitNumber(sum, UNIT.WATT_HOUR);
    return { origin: formattedData.origin, formattedNumber: formattedData.formattedNumber, unit: formattedData.unit };
}
export function getConnectStatus(isConnect, operStatusCd) {
    if (isConnect) {
        return DEVICE_CONNECTION_STATUS['ONLINE'];
    }
    else if (operStatusCd in DEVICE_CONNECTION_STATUS) {
        return DEVICE_CONNECTION_STATUS[operStatusCd];
    }
    else {
        return DEVICE_CONNECTION_STATUS['ONLINE_DISCONNECTED'];
    }
}
export function createColumnHeaderNameList(columnList) {
    return columnList.map((item) => item.header);
}
export const isNullOrUndefined = (value) => typeof value === 'undefined' || value === null;
export function isInteger(value) {
    return value % 1 === 0;
}
export const addSortOrderMark = (sortColumnName, sortOrder) => {
    return sortOrder === SORT_ORDER.ASCENDING ? sortColumnName : `-${sortColumnName}`;
};
export const getSelectorValueByOptions = (options, targetValue) => options.find((option) => option.value === targetValue)?.text;
export const isEmptyString = (value) => {
    return value === '';
};
const createFileUrl = (file, fileName, fileExtension, dateFormat) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = `${fileName}_${moment().utc().format(dateFormat)}.${fileExtension}`;
    return link;
};
export const downloadFile = (file, fileName, fileExtension, dateFormat = 'YYYYMMDDHHmmss') => {
    const link = createFileUrl(file, fileName, fileExtension, dateFormat);
    link.click();
    link.remove();
};
export const defaultInputErrorInfo = {
    isError: false,
    errorMessage: '',
};
export const getDataTypeofValue = (target) => {
    return Object.prototype.toString.call(target).slice(8, -1);
};
export const getNumberLeftPadStringArrayInRange = (startNumber, endNumber, step = 1, padLength = 2) => range(startNumber, endNumber + 1, step).map((numberValue) => padLeft(numberValue, padLength));
/** 범위 겹칩 체크 */
export const isOverlapping = (start1, end1, start2, end2) => start1 < end2 && start2 < end1;
